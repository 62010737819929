import { HttpClientModule } from '@angular/common/http';
import { OpcionaisService } from '../../../services/opcionais.service';
import { TokenService } from '../../../services/token.service';
import { FormGroup, FormBuilder, Validators, FormArray, RequiredValidator } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-opcional',
  templateUrl: './opcional.component.html',
  styleUrls: ['./opcional.component.css']
})
export class OpcionalComponent implements OnInit {
  isSubmitted = false;
  @Output() mudaItems = new EventEmitter();
  opcaoForm: FormGroup;
  idOpcao: string;
  idMarca: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  teste = this.tokenService.GetPayLoad();
  modoEdicao = false;
  produtoSubmitted = false;
  uploadImage = false;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  marcas: any;
  produtos: any;
  gruposCombo: any;
  produtosInclusos = [];
  produtosQuantidade = [];
  moedasSelecionadas = [];

  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private opcionaisService: OpcionaisService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.verificaPermissao();

    this.init();

    this.route.paramMap.subscribe(params => {
      this.idOpcao = params.get('idOpcao');
      this.idMarca = params.get('idMarca');
    });

    if (this.idOpcao) {
      this.modoEdicao = true;
      this.carregaCampos();
    }

    this.obterGrupos();
  }

  obterGrupos() {
    this.opcionaisService.listaGrupos(this.idMarca).subscribe(res => {
      this.gruposCombo = res;
    });
  }

  verificaPermissao() {
    if (!this.user.accesses.special_offers) {
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  init() {
    this.opcaoForm = this.fb.group({
      grupo: ['', Validators.required],
      grupoNovo: ['', Validators.required],
      nomeItem: ['', Validators.required],
      descricao: [''],
      preco: ['']
    });
  }

  reinit() {
    this.opcaoForm = this.fb.group({
      grupo: [this.opcaoForm.value.grupo, Validators.required],
      grupoNovo: ['', (this.opcaoForm.value.grupo === '-1' ? Validators.required : Validators.nullValidator)],
      nomeItem: [this.opcaoForm.value.nomeItem, Validators.required],
      descricao: [this.opcaoForm.value.descricao],
      preco: [this.opcaoForm.value.preco],
    });
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid) {
      this.salvar({
        brand: this.idMarca,
        group: (this.opcaoForm.value.grupo === '-1' ? this.opcaoForm.value.grupoNovo : this.opcaoForm.value.grupo),
        name: this.opcaoForm.value.nomeItem,
        description: (this.opcaoForm.value.descricao ? this.opcaoForm.value.descricao : undefined),
        price: (this.opcaoForm.value.preco ? this.opcaoForm.value.preco : undefined),
      });
    }
  }

  salvar(opcao) {
    if (this.idOpcao) {
      this.opcionaisService.editarOpcao(this.idOpcao, opcao).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('opcionais_cardapios'), this.translatePipe.transform('opcionais_cardapios_editado'), 'success').then(() => {
            this.router.navigate([`admin/opcionais/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('opcionais_cardapios'), error.error.message, 'error');
        }
      );
    } else {
      this.opcionaisService.salvaOpcao(opcao).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('opcionais_cardapios'), this.translatePipe.transform('opcionais_cardapios_cadastrado'), 'success').then(() => {
            this.router.navigate([`admin/opcionais/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('opcionais_cardapios'), error.error.message, 'error');
        }
      );
    }
  }

  cancelar() {
    this.router.navigate([`admin/opcionais/marca/${this.idMarca}`]);
  }

  carregaCampos() {
    this.isSubmitted = true;
    this.opcionaisService.obterOpcao(this.idOpcao).subscribe(res => {
      this.opcaoForm.controls.grupo.setValue(res.group);
      this.opcaoForm.controls.nomeItem.setValue(res.name);
      this.opcaoForm.controls.descricao.setValue(res.description);
      this.opcaoForm.controls.preco.setValue(res.price);

      this.reinit();
    });
    this.opcaoForm.updateValueAndValidity();
  }
}
