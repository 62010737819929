import { CardapiosService } from './../../../services/cardapios.service';
import { TokenService } from './../../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import * as moment from 'moment';

@Component({
  selector: 'app-cardapio',
  templateUrl: './cardapio.component.html',
  styleUrls: ['./cardapio.component.css']
})

export class CardapioComponent implements OnInit {
  isSubmitted = false;

  produtoForm: FormGroup;
  url = null;
  modoEdicao = false;
  uploadImage = false;
  obrigarPreco = true;
  idMarca: any;
  idProduto: any;
  categorias: any;
  destaques: any;
  moedasCombo: any;
  moedasOpcoes: any;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  user = this.tokenService.GetPayLoad();
  moedasSelecionadas = [];
  categoriaSelecionada: any;
  arquivoBase: any;

  constructor(
    private tokenService: TokenService,
    private fb: FormBuilder,
    private cardapiosService: CardapiosService,
    private router: Router,
    private route: ActivatedRoute,
    private translatePipe: TranslatePipe,
  ) { }

  ngOnInit() {
    this.verificaPermissao();

    this.init();
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
      this.idProduto = params.get('idProduto');
    });

    this.obterCategorias();

    this.obterDestaques();

    this.obterMoedas();
  }

  init() {
    this.produtoForm = this.fb.group({
      nomeProduto: ['', Validators.required],
      descricao: [''],
      datahoraevento: ['', Validators.required],
      localevento: ['', Validators.required],
      tipoingresso: ['', Validators.required],
      taxas: ['', Validators.required],
      produzir: [true, Validators.required],
      estoque: [true, Validators.required],
      esgotado: [false, Validators.required],
      bloqueado: [false, Validators.required],
      divulgacao: [false, Validators.required],
      categoria: ['', Validators.required],
      destaque: [''],
      arquivo: [''],
      maximo_extras: [''],
      tempo_preparacao: [''],
      tipo_tempo_preparacao: [''],
      largura: [''],
      altura: [''],
      comprimento: [''],
      diametro: [''],
      peso: [''],
      ingredientes: this.fb.array([]),
      valores: this.fb.array([])
    });
  }

  verificaPermissao() {
    if (!this.user.accesses.brands) {
      if (this.user.accesses.special_offers) {
        this.router.navigate(['admin/promocoes']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  selecionarArquivoBase(files: any): void {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.arquivoBase = { file_name: files[0].name, base64: reader.result };
      this.produtoForm.controls.arquivo.setValue(this.arquivoBase.file_name);
    };
  }

  obterDestaques() {
    this.cardapiosService.listaDestaque(this.idMarca).subscribe(res => {
      this.destaques = res;
    });
  }

  obterCategorias() {
    this.cardapiosService.listaCategoriasProdutos(this.idMarca).subscribe(res => {
      this.categorias = res;
    });
  }

  selecionarCategoria() {
    if (this.valores.length) {
      this.valores.clear();
    }

    this.categoriaSelecionada = this.categorias.filter((categoria) => { return categoria._id === this.produtoForm.value.categoria })[0];

    if (this.categoriaSelecionada.type === "Ticket") {
      this.produtoForm.controls.datahoraevento.enable();
      this.produtoForm.controls.localevento.enable();
      this.produtoForm.controls.tipoingresso.enable();
    }
    else {
      this.produtoForm.controls.datahoraevento.disable();
      this.produtoForm.controls.localevento.disable();
      this.produtoForm.controls.tipoingresso.disable();
    }

    this.incluirValores();
  }

  precoObrigatorio() {
    if (this.produtoForm.value.categoria) {
      return this.categorias.filter((categoria) => {
        return categoria._id === this.produtoForm.value.categoria && categoria.price > 0
      }).length === 0;
    }
    else {
      return true;
    }
  }

  obterMoedas() {
    this.cardapiosService.obterMoedas().subscribe(res => {
      this.moedasCombo = res;

      if (this.idProduto) {
        this.modoEdicao = true;
        this.carregaCampos();
      } else {
        this.incluirIngredientes();
        this.incluirValores();
      }
    });
  }

  selecionaMoeda(currency, event) {
    this.moedasSelecionadas = [];
    this.valores.controls.forEach(control => {
      this.moedasSelecionadas.push(control.value.currency);
    });
  }

  get ingredientes() {
    return this.produtoForm.get('ingredientes') as FormArray;
  }

  incluirIngredientes() {
    const ingrediente = this.fb.group({
      name: [''],
      pullable: [false]
    });

    this.ingredientes.push(ingrediente);
  }

  removerIngredientes(index: number) {
    this.ingredientes.removeAt(index);
  }

  get valores() {
    return this.produtoForm.get('valores') as FormArray;
  }

  incluirValores() {
    this.moedasSelecionadas = [];
    this.valores.controls.forEach(control => {
      this.moedasSelecionadas.push(control.value.currency);
    });
    this.produtoForm.controls.valores['submitted'] = true;

    const valor = this.fb.group({
      value: ['', (this.precoObrigatorio() ? Validators.required : undefined)],
      currency: [(this.moedasCombo.length === 1 ? this.moedasCombo[0]._id : ''), (this.precoObrigatorio() ? Validators.required : undefined)]
    });

    this.valores.push(valor);
  }

  removerValores(index: number) {
    this.moedasSelecionadas.splice(
      this.moedasSelecionadas.indexOf(this.valores.controls[index]['controls'].currency.value),
      1
    );
    this.valores.removeAt(index);
  }

  cancelar() {
    this.router.navigate([`admin/cardapios/marca/${this.idMarca}`]);
  }

  getImage(event) {
    this.image = event;
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;


    if (form.valid && (this.image || this.imageId)) {


      let filteredPrices = [];

      for (let index = 0; index < this.valores.value.length; index++) {
        const valor = this.valores.value[index];

        if (valor !== null && valor?.value !== '' && valor?.value !== null && valor?.currency !== '' && valor?.currency !== null) {
          filteredPrices.push(valor);
        }
      }

      let filteredIngredients = []

      for (let index = 0; index < this.ingredientes.value.length; index++) {
        const ingrediente = this.ingredientes.value[index];

        if (ingrediente !== null && ingrediente?.name !== '' && ingrediente?.name !== null) {
          filteredIngredients.push(ingrediente);
        }
      }

      if (this.image) {
        this.cardapiosService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            brand: this.idMarca,
            name: this.produtoForm.value.nomeProduto,
            description: (this.produtoForm.value.descricao?.length > 0 ? this.produtoForm.value.descricao : undefined),
            event_schedule: (this.produtoForm.value.datahoraevento?.length > 0 ? this.produtoForm.value.datahoraevento : undefined),
            event_address: (this.produtoForm.value.localevento?.length > 0 ? this.produtoForm.value.localevento : undefined),
            event_ticket_type: (this.produtoForm.value.tipoingresso?.length > 0 ? this.produtoForm.value.tipoingresso : undefined),
            prices: filteredPrices,
            tax: this.produtoForm.value.taxas,
            produce: this.produtoForm.value.produzir,
            stock: this.produtoForm.value.estoque,
            soldoff: this.produtoForm.value.esgotado,
            blocked: this.produtoForm.value.bloqueado,
            in_disclosure: this.produtoForm.value.divulgacao,
            categories: [this.produtoForm.value.categoria],
            highlights: this.produtoForm.value.destaque ? this.produtoForm.value.destaque : undefined,
            max_extras: (this.produtoForm.value.maximo_extras ? this.produtoForm.value.maximo_extras : undefined),
            setup_time: (this.produtoForm.value.tempo_preparacao ? this.produtoForm.value.tempo_preparacao : undefined),
            setup_time_type: (this.produtoForm.value.tipo_tempo_preparacao ? this.produtoForm.value.tipo_tempo_preparacao : undefined),
            ingredients: filteredIngredients,
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            },
            dimension: {
              length: (this.produtoForm.value.comprimento !== '' ? this.produtoForm.value.comprimento : undefined),
              width: (this.produtoForm.value.largura !== '' ? this.produtoForm.value.largura : undefined),
              height: (this.produtoForm.value.altura !== '' ? this.produtoForm.value.altura : undefined),
              diameter: (this.produtoForm.value.diametro !== '' ? this.produtoForm.value.diametro : undefined),
              weight: (this.produtoForm.value.peso !== '' ? this.produtoForm.value.peso : undefined),
            },
            base_file: this.arquivoBase
          });
        });
      }
      else {
        this.salvar({
          brand: this.idMarca,
          name: this.produtoForm.value.nomeProduto,
          description: (this.produtoForm.value.descricao?.length > 0 ? this.produtoForm.value.descricao : undefined),
          event_schedule: (this.produtoForm.value.datahoraevento?.length > 0 ? this.produtoForm.value.datahoraevento : undefined),
          event_address: (this.produtoForm.value.localevento?.length > 0 ? this.produtoForm.value.localevento : undefined),
          event_ticket_type: (this.produtoForm.value.tipoingresso?.length > 0 ? this.produtoForm.value.tipoingresso : undefined),
          prices: filteredPrices,
          tax: this.produtoForm.value.taxas,
          produce: this.produtoForm.value.produzir,
          stock: this.produtoForm.value.estoque,
          soldoff: this.produtoForm.value.esgotado,
          blocked: this.produtoForm.value.bloqueado,
          in_disclosure: this.produtoForm.value.divulgacao,
          categories: [this.produtoForm.value.categoria],
          highlights: this.produtoForm.value.destaque ? this.produtoForm.value.destaque : undefined,
          max_extras: (this.produtoForm.value.maximo_extras ? this.produtoForm.value.maximo_extras : undefined),
          setup_time: (this.produtoForm.value.tempo_preparacao ? this.produtoForm.value.tempo_preparacao : undefined),
          setup_time_type: (this.produtoForm.value.tipo_tempo_preparacao ? this.produtoForm.value.tipo_tempo_preparacao : undefined),
          ingredients: filteredIngredients,
          image: {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          },
          dimension: {
            length: (this.produtoForm.value.comprimento !== '' ? this.produtoForm.value.comprimento : undefined),
            width: (this.produtoForm.value.largura !== '' ? this.produtoForm.value.largura : undefined),
            height: (this.produtoForm.value.altura !== '' ? this.produtoForm.value.altura : undefined),
            diameter: (this.produtoForm.value.diametro !== '' ? this.produtoForm.value.diametro : undefined),
            weight: (this.produtoForm.value.peso !== '' ? this.produtoForm.value.peso : undefined),
          },
          base_file: this.arquivoBase
        });
      }

    }
  }

  salvar(produtoForm) {
    if (this.idProduto) {
      this.cardapiosService.editarProduto(this.idProduto, produtoForm).subscribe(
        res => {
          Swal.fire('Produto', 'Produto editado com sucesso!', 'success').then(() => {
            this.router.navigate([`admin/cardapios/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire('Produto', this.translatePipe.transform(error.error.message), 'error');
        }
      );
    } else {
      this.cardapiosService.salvarProduto(produtoForm).subscribe(
        res => {
          Swal.fire('Produto', 'Produto cadastrado com sucesso!', 'success').then(() => {
            this.router.navigate([`admin/cardapios/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire('Produto', this.translatePipe.transform(error.error.message), 'error');
        }
      );
    }
  }

  // Editar
  carregaCampos() {
    this.cardapiosService.obterDadosProduto(this.idProduto).subscribe(res => {
      this.isSubmitted = true;
      this.produtoForm.controls.nomeProduto.setValue(res.name);
      this.produtoForm.controls.descricao.setValue(res.description);

      this.produtoForm.controls.taxas.setValue(res.tax);
      this.produtoForm.controls.categoria.setValue(res.categories[0]._id);
      this.categoriaSelecionada = this.categorias.filter((categoria) => { return categoria._id === res.categories[0]._id })[0];

      if (this.categoriaSelecionada.type === "Ticket") {
        this.produtoForm.controls.datahoraevento.enable();
        this.produtoForm.controls.localevento.enable();
        this.produtoForm.controls.tipoingresso.enable();
      }
      else {
        this.produtoForm.controls.datahoraevento.disable();
        this.produtoForm.controls.localevento.disable();
        this.produtoForm.controls.tipoingresso.disable();
      }

      this.produtoForm.controls.datahoraevento.setValue(moment(res.event_schedule).format("YYYY-MM-DDTHH:mm"));
      this.produtoForm.controls.localevento.setValue(res.event_address);
      this.produtoForm.controls.tipoingresso.setValue(res.event_ticket_type);

      this.produtoForm.controls.destaque.setValue(res.highlights ? res.highlights : '');

      this.produtoForm.controls.arquivo.setValue(res.base_file?.file_name);

      this.produtoForm.controls.maximo_extras.setValue(res.max_extras);
      this.produtoForm.controls.tempo_preparacao.setValue(res.setup_time);
      this.produtoForm.controls.tipo_tempo_preparacao.setValue(res.setup_time_type ? res.setup_time_type : '');
      this.produtoForm.controls.produzir.setValue(res.produce);
      this.produtoForm.controls.estoque.setValue(res.stock);
      this.produtoForm.controls.esgotado.setValue(res.soldoff);
      this.produtoForm.controls.bloqueado.setValue(res.blocked);
      this.produtoForm.controls.divulgacao.setValue(res.in_disclosure);

      this.produtoForm.controls.largura.setValue(res.dimension?.width);
      this.produtoForm.controls.altura.setValue(res.dimension?.height);
      this.produtoForm.controls.comprimento.setValue(res.dimension?.length);
      this.produtoForm.controls.diametro.setValue(res.dimension?.diameter);
      this.produtoForm.controls.peso.setValue(res.dimension?.weight);

      res.ingredients.forEach(element => {
        const ingrediente = this.fb.group({
          name: [element.name, Validators.required],
          pullable: [element.pullable]
        });
        this.ingredientes.push(ingrediente);
      });

      if (this.ingredientes.length === 0) {
        this.incluirIngredientes();
      }

      res.prices.forEach(item => {
        this.moedasSelecionadas.push(item.currency);
        const valor = this.fb.group({
          value: [item.value, Validators.required],
          currency: [item.currency, Validators.required]
        });
        this.valores.push(valor);
      });

      this.imageId = res.image.id;
      this.imageUrl = res.image.url;
      this.imageSecureUrl = res.image.secure_url;
      this.url = res.image.url;
    });
  }
}
