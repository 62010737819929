import { CategoriasService } from '../../services/categorias.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  categorias = [];
  marca: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  idMarca: any;

  constructor(private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private categoriasService: CategoriasService,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {

    this.verificaPermissao();
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
    });

    this.listaCategorias();
  }

  verificaPermissao() {
    if (!this.user.accesses.special_offers) {
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  listaCategorias() {
    this.categoriasService.listaCategoriasProdutos(this.idMarca).subscribe(res => {
      this.categorias = res;
    });
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('gerenciamento_categorias_cardapios_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.categoriasService.excluirCategoria(id).subscribe((res: any) => {
          this.listaCategorias();
        });
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/categorias/marca/${this.idMarca}/categoria/${id}/editar`]);
  }
}
