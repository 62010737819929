import { HttpClientModule } from '@angular/common/http';
import { TokenService } from './../../../services/token.service';
import { UsuariosService } from './../../../services/usuarios.service';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { PlataformaService } from 'src/app/services/plataforma.service';

@Component({
  selector: 'app-gerenciamento-usuario',
  templateUrl: './gerenciamento-usuario.component.html',
  styleUrls: ['./gerenciamento-usuario.component.css']
})
export class GerenciamentoUsuarioComponent implements OnInit {
  isSubmitted = false;
  usuarioForm: FormGroup;
  idUsuario: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  modoEdicao = false;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  uploadImage = false;

  marcasCombo: any;

  unidadesCombo: any;
  unidadesSelecionadas = [];
  marcasSelecionadas = [];

  mostrarCpf: boolean = true;
  mostrarNif: boolean = false;


  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private usuariosService: UsuariosService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private plataformaService: PlataformaService
  ) { }

  ngOnInit() {

    this.verificaPermissao();
    this.init();
  }

  verificaPermissao() {
    if (!this.user.accesses.configurations) {
      if (this.user.accesses.special_offers) {
        this.router.navigate(['admin/promocoes']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
    }
  }

  init() {
    this.usuarioForm = this.fb.group({
      nomeUsuario: ['', Validators.required],
      emailUsuario: ['', [Validators.required, Validators.email]],
      cpfUsuario: [''],
      nifUsuario: [''],
      campoUnidade: [''],
      campoMarca: [''],
      campoPerfil: [''],
      unidades: this.fb.array([]),
      marcas: this.fb.array([])
    });

    this.plataformaService.listaConfiguracoes().subscribe((res) => {
      if (res.length) {
        if (res[0].cnpj === true) {
          this.mostrarCpf = true;
          this.mostrarNif = false;
        } else {
          this.mostrarCpf = false;
          this.mostrarNif = true;
        }
      }

      if (this.mostrarCpf === true) {
        this.usuarioForm = this.fb.group({
          nomeUsuario: ['', Validators.required],
          emailUsuario: ['', [Validators.required, Validators.email]],
          cpfUsuario: ['', [Validators.required]],
          nifUsuario: [''],
          campoUnidade: [''],
          campoMarca: [''],
          campoPerfil: [''],
          unidades: this.fb.array([]),
          marcas: this.fb.array([])
        });
      } else {
        this.usuarioForm = this.fb.group({
          nomeUsuario: ['', Validators.required],
          emailUsuario: ['', [Validators.required, Validators.email]],
          cpfUsuario: [''],
          nifUsuario: ['', [Validators.required]],
          campoUnidade: [''],
          campoMarca: [''],
          campoPerfil: [''],
          unidades: this.fb.array([]),
          marcas: this.fb.array([])
        });
      }

      this.route.paramMap.subscribe(params => {
        this.idUsuario = params.get('id');

        this.obterMarcas();
        this.obterUnidades();

        if (this.idUsuario) {
          this.modoEdicao = true;
          this.carregaCampos();
        } else {
          this.incluirMarcas();
          this.incluirUnidades();
        }
      });

    });
  }

  obterMarcas() {
    this.usuariosService.listaMarcas().subscribe(res => {
      this.marcasCombo = res;
    });
  }

  get marcas() {
    return this.usuarioForm.get('marcas') as FormArray;
  }

  incluirMarcas() {
    const marca = this.fb.group({
      campoMarca: ['']
    });

    this.marcas.push(marca);
  }

  removerMarcas(index: number) {
    this.marcas.removeAt(index);
  }


  obterUnidades() {
    this.usuariosService.listaUnidades().subscribe(res => {
      this.unidadesCombo = res;
    });
  }

  get unidades() {
    return this.usuarioForm.get('unidades') as FormArray;
  }

  incluirUnidades() {
    const unidade = this.fb.group({
      campoUnidade: ['']
    });

    this.unidades.push(unidade);
  }

  removerUnidades(index: number) {
    this.unidades.removeAt(index);
  }

  isValidCPF() {
    let cpf = this.usuarioForm.value.cpfUsuario;

    if (!cpf) {
      return true;
    }

    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    ) {
      return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
  }

  getImage(event) {
    this.image = event;
  }

  isValidMarcas() {
    let marcas = [];

    this.marcas.value.map(item => {
      if (item.campoMarca) {
        if (item.campoMarca.length > 0) {
          marcas.push(item.campoMarca);
        }
      }
    });
    if (marcas.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  isValidUnidades() {
    let unidades = [];

    this.unidades.value.map(item => {
      if (item.campoUnidade) {
        if (item.campoUnidade.length > 0) {
          unidades.push(item.campoUnidade);
        }
      }
    });
    if (unidades.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;

    if (form.valid
      && ((this.usuarioForm.value.campoPerfil === `stores_administrator` && this.isValidMarcas() == true)
        || (this.usuarioForm.value.campoPerfil === `employee` && this.isValidUnidades() == true)
        || (this.usuarioForm.value.campoPerfil === `delivery_person` && this.isValidUnidades() == true)
        || (this.usuarioForm.value.campoPerfil === `account_manager`)
        || (this.usuarioForm.value.campoPerfil === `market_place_administrator`))) {

      this.usuarioForm.updateValueAndValidity();
      this.unidades.value.map(item => {
        this.unidadesSelecionadas.push(item.campoUnidade);
      });
      if (this.unidadesSelecionadas[0] === '') {
        this.unidadesSelecionadas = [];
      }
      this.marcas.value.map(item => {
        this.marcasSelecionadas.push(item.campoMarca);
      });
      if (this.marcasSelecionadas[0] === '') {
        this.marcasSelecionadas = [];
      }

      if (this.usuarioForm.value.campoPerfil === `employee` || this.usuarioForm.value.campoPerfil === `delivery_person`) {
        this.unidades.value.map(item => {

          let brand = this.unidadesCombo.filter((unidade) => { return unidade._id === item.campoUnidade });

          if (this.marcasSelecionadas.filter((marca) => { return marca === brand[0].brand._id }).length === 0) {
            this.marcasSelecionadas.push(brand[0].brand._id);
          }
        });
      }

      if (this.image) {
        this.usuariosService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            name: this.usuarioForm.value.nomeUsuario,
            cpf: this.usuarioForm.value.cpfUsuario ? this.usuarioForm.value.cpfUsuario : undefined,
            nif: this.usuarioForm.value.nifUsuario ? this.usuarioForm.value.nifUsuario : undefined,
            email: this.usuarioForm.value.emailUsuario,
            role: this.usuarioForm.value.campoPerfil,
            restaurants: this.unidadesSelecionadas,
            brands: this.marcasSelecionadas,
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            },
          });
        });
      }
      else {
        this.salvar({
          name: this.usuarioForm.value.nomeUsuario,
          cpf: this.usuarioForm.value.cpfUsuario ? this.usuarioForm.value.cpfUsuario : undefined,
          nif: this.usuarioForm.value.nifUsuario ? this.usuarioForm.value.nifUsuario : undefined,
          email: this.usuarioForm.value.emailUsuario,
          role: this.usuarioForm.value.campoPerfil,
          restaurants: this.unidadesSelecionadas,
          brands: this.marcasSelecionadas,
          image: this.imageId ? {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          } : {}
        });
      }
    }

  }

  salvar(usuarioForm) {
    if (this.idUsuario) {
      this.usuariosService.editarUsuario(this.idUsuario, usuarioForm).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('usuario_titulo'), this.translatePipe.transform('usuario_editado'), 'success').then(() => {
            this.router.navigate(['admin/gerenciamento-usuarios']);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('usuario_titulo'), error.error.message, 'error');
        }
      );
    } else {
      this.usuariosService.salvarUsuario(usuarioForm).subscribe(
        res => {
          this.usuariosService.novaSenhaUsuario({ email: usuarioForm.email }).subscribe(
            res => {
              Swal.fire(this.translatePipe.transform('usuario_titulo'), this.translatePipe.transform('usuario_cadastrado'), 'success').then(() => {
                this.router.navigate(['admin/gerenciamento-usuarios']);
              });
            }
          );
        },
        error => {
          Swal.fire(this.translatePipe.transform('usuario_titulo'), this.translatePipe.transform(error.error.message), 'error');
        }
      );
    }

    this.unidadesSelecionadas = [];
    this.marcasSelecionadas = [];
  }

  cancelar() {
    this.router.navigate(['admin/gerenciamento-usuarios']);
  }

  // Editar
  carregaCampos() {
    this.usuariosService.obterDadosUsuario(this.idUsuario).subscribe(res => {
      this.isSubmitted = true;
      this.usuarioForm.controls.nomeUsuario.setValue(res.name);
      this.usuarioForm.controls.cpfUsuario.setValue(res.cpf);
      this.usuarioForm.controls.nifUsuario.setValue(res.nif);
      this.usuarioForm.controls.emailUsuario.setValue(res.email);
      this.usuarioForm.controls.campoPerfil.setValue(res.role);

      res.restaurants.forEach(element => {
        const unidade = this.fb.group({
          campoUnidade: [element._id]
        });
        this.unidades.push(unidade);
      });

      if (res.restaurants.length === 0) {
        this.incluirUnidades();
      }

      res.brands.forEach(element => {
        const brand = this.fb.group({
          campoMarca: [element]
        });
        this.marcas.push(brand);
      });

      if (res.brands.length === 0) {
        this.incluirMarcas();
      }

      this.imageId = res.image?.id;
      this.imageUrl = res.image?.url;
      this.imageSecureUrl = res.image?.secure_url;
      this.url = res.image?.url;
    });
  }
}
