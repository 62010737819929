import { MarcasService } from './../../services/marcas.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-marcas',
  templateUrl: './marcas.component.html',
  styleUrls: ['./marcas.component.css']
})
export class MarcasComponent implements OnInit {
  marcas = [];
  categorias = [];
  url = null;
  user = this.tokenService.GetPayLoad();
  constructor(private translatePipe: TranslatePipe, private tokenService: TokenService, private marcasService: MarcasService, private router: Router) { }

  ngOnInit() {

    this.verificaPermissao();
    this.listaMarcas();
  }

  verificaPermissao() {
    if (!this.user.accesses.brands) {
      if (this.user.accesses.special_offers) {
        this.router.navigate(['admin/promocoes']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  listaMarcas() {
    this.marcasService.listaMarcas().subscribe(res => {

      res.map((loja) => {
        if (this.categorias.filter((categoria) => { return categoria._id === loja.categories[0]._id }).length === 0) {
          this.categorias.push(loja.categories[0]);
        }

        loja.situacao = (loja.isActive ? 'Ativa' : 'Inativa');
      });

      this.categorias.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return 1;
        return 0;
      });

      this.marcas = res;
    });
  }

  marcasFiltros(categoria) {
    let marcasFiltradas = this.marcas.filter((loja) => { return loja.categories[0]._id === categoria._id });
    marcasFiltradas.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase())
        return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase())
        return 1;
      return 0;
    });

    return marcasFiltradas;
  }

  excluirMarca(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('deletar_marcas'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.marcasService.excluirMarca(id).subscribe(
          (res: any) => {
            this.listaMarcas();
          },
          error => {
            Swal.fire(this.translatePipe.transform('marcas'), this.translatePipe.transform(error.error.message), 'error');
          }
        );
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/marcas/editar/${id}`]);
  }

  redirecionaUnidades(id: string) {
    this.router.navigate([`admin/unidades/marca/${id}`]);
  }

  redirecionaCategorias(id: string) {
    this.router.navigate([`admin/categorias/marca/${id}`]);
  }

  redirecionaOpcionais(id: string) {
    this.router.navigate([`admin/opcionais/marca/${id}`]);
  }

  redirecionaCardapios(id: string) {
    this.router.navigate([`admin/cardapios/marca/${id}`]);
  }

  redirecionaPromocoes(id: string) {
    this.router.navigate([`admin/promocoes/marca/${id}`]);
  }

  redirecionaCupons(id: string) {
    this.router.navigate([`admin/cupons/marca/${id}`]);
  }

  redirecionaDestaques(id: string) {
    this.router.navigate([`admin/destaques-aplicativo/marca/${id}`]);
  }
}
