<app-toolbar></app-toolbar>
<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"
                  [readOnly]="somenteLeitura"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s12"
        [formGroup]="marcaForm"
        novalidate
        (ngSubmit)="onSubmit(marcaForm)">

    <h5>Identificação</h5>

    <div class="row">
      <div class="input-field col s6">
        <input id="nomeMarca"
               type="text"
               formControlName="nomeMarca">
        <label for="nomeMarca"
               [class.active]="modoEdicao">{{'campo_nome_marca' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['nomeMarca'].invalid && 
              (marcaForm.controls['nomeMarca'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s6">
        <input id="razaoSocial"
               type="text"
               formControlName="razaoSocial">
        <label for="razaoSocial"
               [class.active]="modoEdicao">{{'marca_nome_compania' | translate}}</label>
      </div>
    </div>

    <div *ngIf="mostrarCnpj"
         class="row">
      <div class="input-field col s4">
        <input id="cnpj"
               type="text"
               formControlName="cnpj">
        <label for="cnpj"
               [class.active]="modoEdicao">{{'marca_id' | translate}}</label>
      </div>
      <div class="input-field col s4">
        <input id="inscricaoEstadual"
               type="text"
               formControlName="inscricaoEstadual">
        <label for="inscricaoEstadual"
               [class.active]="modoEdicao">{{'marca_id_estadual' | translate}}</label>
      </div>
      <div class="input-field col s4">
        <input id="inscricaoMunicipal"
               type="text"
               formControlName="inscricaoMunicipal">
        <label for="inscricaoMunicipal"
               [class.active]="modoEdicao">{{'marca_id_municipal' | translate}}</label>
      </div>
    </div>

    <div *ngIf="mostrarNif"
         class="row">
      <div class="input-field col s4">
        <input id="nif"
               type="text"
               formControlName="nif">
        <label for="nif"
               [class.active]="modoEdicao">{{'marca_nif' | translate}}</label>
      </div>
    </div>


    <h5>Contato</h5>
    <div class="row">

      <div class="input-field col s3">
        <input id="zipCode"
               type="text"
               (blur)="buscarCEP()"
               formControlName="zipCode">
        <label for="zipCode"
               [class.active]="modoEdicao">{{'campo_zip' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['zipCode'].invalid && 
          (marcaForm.controls['zipCode'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s4">
        <input id="rua"
               type="text"
               formControlName="rua">
        <label for="rua"
               [class.active]="modoEdicao">{{'campo_endereco' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['rua'].invalid && 
          (marcaForm.controls['rua'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="numero"
               type="text"
               formControlName="numero">
        <label for="numero"
               [class.active]="modoEdicao">{{'campo_endereco_numero' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['numero'].invalid && 
          (marcaForm.controls['numero'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s3">
        <input id="complemento"
               type="text"
               formControlName="complemento">
        <label for="complemento"
               [class.active]="modoEdicao">{{'campo_complemento' | translate}}</label>
      </div>
    </div>

    <div class="row">

      <div class="input-field col s5">
        <input id="bairro"
               type="text"
               formControlName="bairro">
        <label for="bairro"
               [class.active]="modoEdicao">{{'campo_bairro' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['bairro'].invalid && 
          (marcaForm.controls['bairro'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s5">
        <input id="cidade"
               type="text"
               formControlName="cidade">
        <label for="cidade"
               [class.active]="modoEdicao">{{'campo_cidade' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['cidade'].invalid && 
          (marcaForm.controls['cidade'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2">
        <input id="estado"
               type="text"
               formControlName="estado">
        <label for="estado"
               [class.active]="modoEdicao">{{'campo_estado' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['estado'].invalid && 
          (marcaForm.controls['estado'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div class="row">

      <div class="input-field col s2">
        <input id="telefone"
               type="tel"
               formControlName="telefone">
        <label for="telefone"
               [class.active]="modoEdicao">{{'campo_telefone' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['telefone'].invalid && 
          (marcaForm.controls['telefone'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s5">
        <input id="email"
               type="text"
               formControlName="email">
        <label for="email"
               [class.active]="modoEdicao">{{'campo_email' | translate}}</label>
        <span class="error"
              *ngIf="marcaForm.controls['email'].invalid && 
          (marcaForm.controls['email'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s5"
           style="margin-top: -12px;">
        <label class="labelSelect">{{'campo_responsavel' | translate}}</label> <br />
        <select materialize
                id="responsavel"
                formControlName="responsavel"
                style="margin-top: 10px;">
          <option value=""
                  selected>{{'descricao_responsavel' | translate}}</option>
          <option *ngFor="let responsavel of responsaveis"
                  [ngValue]="responsavel._id">{{ responsavel.name }}</option>

        </select>
      </div>
    </div>

    <h5>{{'marca_banco_dados' | translate}}</h5>

    <div class="row">
      <div class="input-field col s4">
        <input id="banco"
               type="text"
               formControlName="banco">
        <label for="banco"
               [class.active]="modoEdicao">{{'marca_banco_nome' | translate}}</label>
      </div>
      <div class="input-field col s2">
        <input id="bancoCodigo"
               type="text"
               maxlength="3"
               formControlName="bancoCodigo">
        <label for="bancoCodigo"
               [class.active]="modoEdicao">{{'marca_banco_codigo' | translate}}</label>
      </div>
      <div class="input-field col s2">
        <input id="bancoAgencia"
               type="text"
               maxlength="4"
               formControlName="bancoAgencia">
        <label for="bancoAgencia"
               [class.active]="modoEdicao">{{'marca_banco_agencia' | translate}}</label>
      </div>
      <div class="input-field col s2">
        <input id="bancoConta"
               type="text"
               maxlength="14"
               formControlName="bancoConta">
        <label for="bancoConta"
               [class.active]="modoEdicao">{{'marca_banco_conta' | translate}}</label>
      </div>
      <div class="input-field col s2">
        <input id="bancoContaDigito"
               type="text"
               maxlength="2"
               formControlName="bancoContaDigito">
        <label for="bancoContaDigito"
               [class.active]="modoEdicao">{{'marca_banco_conta_digito' | translate}}</label>
      </div>
    </div>

    <h5>Parâmetros</h5>

    <div class="row">


      <div class="input-field col s3">
        <label class="labelSelect">{{'categorias_marca' | translate}}</label> <br />
        <select materialize
                id="categoria"
                formControlName="categoria"
                style="margin-top: 10px;">
          <option value=""
                  disabled
                  selected>{{'texto_selecione' | translate}}</option>
          <option *ngFor="let categoria of categorias"
                  [ngValue]="categoria._id">{{ categoria.name }}</option>

        </select>

        <span class="error"
              style="margin-top: 0.5rem;"
              *ngIf="marcaForm.controls['categoria'].invalid && (marcaForm.controls['categoria'].touched || isSubmitted)">
          Campo obrigatório
        </span>
      </div>

      <div class="input-field col s4">
        <label class="labelSelect">{{'marca_taxas' | translate}}</label> <br />
        <select materialize
                id="taxa"
                formControlName="taxa"
                style="margin-top: 10px;">
          <option value=""
                  disabled
                  selected>{{'texto_selecione' | translate}}</option>
          <option *ngFor="let taxa of taxas"
                  [ngValue]="taxa._id">{{ taxa.name }}</option>

        </select>

        <span class="error"
              style="margin-top: 0.5rem;"
              *ngIf="marcaForm.controls['taxa'].invalid && (marcaForm.controls['taxa'].touched || isSubmitted)">
          Campo obrigatório
        </span>
      </div>

      <div *ngIf="user.accesses.market_place_administrator || user.accesses.account_manager"
           class="input-field col s2">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'mostrar_preco_delivery_app' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="precodelivery" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div *ngIf="!user.accesses.market_place_administrator && !user.accesses.account_manager"
           class="input-field col s2">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'mostrar_preco_delivery_app' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="precodelivery"
                     disabled />
              <span></span>
            </label>
          </div>
        </span>
      </div>

      <div *ngIf="user.accesses.market_place_administrator || user.accesses.account_manager"
           class="input-field col s3">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'marca_ativa' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="lojaativa" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div *ngIf="!user.accesses.market_place_administrator && !user.accesses.account_manager"
           class="input-field col s3">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'marca_ativa' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="lojaativa"
                     disabled />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>





    <div class="row center">
      <button class="btn btnCancelar waves-effect"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>

      <button *ngIf="!somenteLeitura"
              class="btn btnSalvar waves-effect"
              id="salvar"
              type="submit"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>
</div>