import Swal from 'sweetalert2';
import { PedidosService } from './../../services/pedidos.service';
import { TokenService } from '../../services/token.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-controle-entregas',
  templateUrl: './controle-entregas.component.html',
  styleUrls: ['./controle-entregas.component.css']
})
export class EntregasComponent implements OnInit {
  pedidos = [];

  entregadores = [];
  dadosEntregador = [];

  pedidosLiberados = [];
  pedidosEmEntrega = [];
  pedidosEntregues = [];
  user = this.tokenService.GetPayLoad();

  tempoAtualizacao: any = 60;

  constructor(
    private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private pedidosService: PedidosService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.verificaPermissao();

    this.filtrarPedidos();
    this.obterEntregadores();
    
    Observable.timer(0, 1000)
    .takeWhile(() => true) // Apenas com o componente ativo
    .subscribe(() => {
      this.regredirTempo();
    });
  }

  regredirTempo() {
    this.tempoAtualizacao = this.tempoAtualizacao - 1;

    if (this.tempoAtualizacao <= 0) {
      this.filtrarPedidos();
    }

  }

  atualizarTela() {
    this.filtrarPedidos();
  }

  verificaPermissao() {
    if (!this.user.accesses.operations) {
      if (this.user.accesses.special_offers) {
        this.router.navigate(['admin/promocoes']);
      }
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  filtrarPedidos() {
    this.pedidosService.obterPedidos().subscribe(res => {
      this.pedidos = res;

      this.pedidosLiberados = [];
      this.pedidosEmEntrega = [];
      this.pedidosEntregues = [];  

      this.pedidos.forEach(pedido => {
        switch (pedido.status) {
          case 'ready':
            this.pedidosLiberados.push(pedido);
            break;
          case 'delivering':
            this.pedidosEmEntrega.push(pedido);
            break;
          case 'delivered':
            this.pedidosEntregues.push(pedido);
            break;
        }
      });

      this.pedidos.map(pedido => {
        pedido.entregadorSelecionado = '';
      });
      
      this.tempoAtualizacao = 60;
    });

  }

  obterEntregadores() {
    this.pedidosService.obterEntregadores().subscribe(res => {
      this.entregadores = res;
    });
  }

  mudaStatusPedido(_id: string, entregadorSelecionado: any, pedido: any) {

    let delivery_method = pedido.delivery_method?.method;

    if (delivery_method === 'takeaway' || delivery_method === 'takeway' || delivery_method === 'postal_delivery') {


      this.pedidos.map(pedido => {
        if (pedido._id === _id) {
          switch (pedido.status) {
            case 'ready':
              let statusDelivering = { status: 'delivering' };
              this.pedidosService.trocarStatus(_id, statusDelivering).subscribe(res => {
                this.filtrarPedidos();
              });
              break;
            case 'delivering':
              let statusDelivered = { status: 'delivered' };
              this.pedidosService.trocarStatus(_id, statusDelivered).subscribe(res => {
                this.filtrarPedidos();
              });
              break;
          }
        }
      });
    }

    else if (entregadorSelecionado) {

      this.pedidos.map(pedido => {
        if (pedido._id === _id) {
          switch (pedido.status) {
            case 'ready':
              let statusDelivering = { status: 'delivering' };
              this.pedidosService.trocarStatus(_id, statusDelivering).subscribe(res => {
                this.filtrarPedidos();
              });
              break;
          }
        }
      });
    } else {
      Swal.fire({
        title: this.translatePipe.transform('entregador_mensagem_titulo'),
        text: this.translatePipe.transform('entregador_mensagem'),
      });
    }
  }

  mudaStatusPedidoRetorna(_id: string) {

    this.pedidos.map(pedido => {
      if (pedido._id === _id) {
        switch (pedido.status) {
          case 'delivering':
            let statusReady = { status: 'ready' };
            this.pedidosService.trocarStatus(_id, statusReady).subscribe(res => {
              this.filtrarPedidos();
            });
            break;
        }
      }
    });
  }

  vinculaEntregador(id: string, _id: string) {
    this.entregadores.forEach(entregador => {
      if (entregador._id === id) {
        const dados = {
          name: entregador.name,
          ref: entregador._id
        };

        this.pedidosService.vinculaEntregador(_id, dados).subscribe(res => {
          this.mudaStatusPedido(_id, entregador._id, {});
        });
      }
    });
  }
}
