import { EstoqueService } from '../../services/estoque.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-estoque',
  templateUrl: './estoque.component.html',
  styleUrls: ['./estoque.component.css']
})
export class EstoqueComponent implements OnInit {
  isSubmitted = false;

  estoque = [];
  idMarca: any;
  idUnidade: any;
  user = this.tokenService.GetPayLoad();
  constructor(private tokenService: TokenService,
    private fb: FormBuilder,
    private estoqueService: EstoqueService,
    private router: Router,
    private route: ActivatedRoute,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.verificaPermissao();

    this.init();

    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
      this.idUnidade = params.get('idUnidade');
    });
    this.listaItens();
  }

  verificaPermissao() {
    if (!this.user.accesses.brands) {
      if (this.user.accesses.special_offers) {
        this.router.navigate(['admin/promocoes']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  init() {

  }

  estoqueDigital(item) {
    this.router.navigate([`admin/unidades/marca/${this.idMarca}/unidade/${this.idUnidade}/estoque/${item._id}/estoque-digital`]);
  }

  estoqueIngressos(item) {
    this.router.navigate([`admin/unidades/marca/${this.idMarca}/unidade/${this.idUnidade}/estoque/${item._id}/estoque-ingressos`]);
  }

  salvar() {
    this.isSubmitted = true;

    let estoque = [];
    this.estoque.map((item) => {
      estoque.push({
        restaurant: this.idUnidade,
        item: item._id,
        actual_quantity: item.actual_quantity,
        new_quantity: item.new_quantity
      });
    });

    this.estoqueService.salvaItens({ stock: estoque }).subscribe(
      res => {
        Swal.fire(this.translatePipe.transform('estoque'), this.translatePipe.transform('estoque_atualizado'), 'success').then(() => {
          this.listaItens();

          this.isSubmitted = false;
        });
      },
      error => {
        Swal.fire('Categoria', error.error.message, 'error');
      }
    );
  }

  listaItens() {
    this.estoqueService.listaItens(this.idUnidade).subscribe(res => {
      this.estoque = res;
    });
  }


  voltar(id) {
    this.router.navigate([`admin/unidades/marca/${id}`]);
  }
}
