import { OpcionaisService } from '../../services/opcionais.service';
import { TokenService } from '../../services/token.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-opcionais',
  templateUrl: './opcionais.component.html',
  styleUrls: ['./opcionais.component.css']
})
export class OpcionaisComponent implements OnInit {
  grupos = [];
  opcionais = [];
  marca: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  idMarca: any;

  constructor(private translatePipe: TranslatePipe,
    private tokenService: TokenService,
    private opcionaisService: OpcionaisService,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {

    this.verificaPermissao();
    this.route.paramMap.subscribe(params => {
      this.idMarca = params.get('idMarca');
    });

    this.listaOpcionais();
  }

  obterGrupos() {
    this.opcionaisService.listaGrupos(this.idMarca).subscribe(res => {
      this.grupos = res;
    });
  }

  opcionaisFiltros(grupo) {
    return this.opcionais.filter((opcao) => { return opcao.group === grupo });
  }

  verificaPermissao() {
    if (!this.user.accesses.special_offers) {
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  listaOpcionais() {
    this.opcionaisService.listaOpcoes(this.idMarca).subscribe(res => {
      this.opcionais = res;

      this.obterGrupos();
    });
  }

  excluir(id: string) {
    Swal.fire({
      title: this.translatePipe.transform('deletar_titulos'),
      text: this.translatePipe.transform('opcionais_cardapios_excluir'),
      showCancelButton: true,
      confirmButtonText: this.translatePipe.transform('deletar_sim'),
      cancelButtonText: this.translatePipe.transform('deletar_nao'),
    }).then(result => {
      if (result.value) {
        this.opcionaisService.excluirOpcao(id).subscribe((res: any) => {
          this.listaOpcionais();
        });
      }
    });
  }

  editar(id: string) {
    this.router.navigate([`admin/opcionais/marca/${this.idMarca}/opcional/${id}/editar`]);
  }
}
