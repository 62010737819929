import { Injectable } from '@angular/core';

@Injectable()
export class TranslationService {
  public languages = [
    { label: 'Português', icon: 'brazil', value: 'pt_br' },
    { label: 'Inglês', icon: 'united-states', value: 'eng' },
  ];

  public language = 'pt_br';

  private dictionary: { [key: string]: TranslationSet } = {
    pt_br: {
      languange: 'pt_br',
      values: {
        //genéricos
        texto_adicionar: 'Adicionar',
        texto_excluir: 'Excluir',
        texto_nova_senha: 'Nova Senha',
        texto_confirmar_senha: 'Confirmar Senha',
        texto_editar: 'Editar',
        texto_upload: 'Enviar',
        texto_enviar: 'Enviar',
        texto_salvar: 'Salvar',
        texto_confirmar: 'Confirmar',
        texto_cancelar: 'Voltar',
        texto_voltar: 'Voltar',
        texto_acao: 'Ação',
        texto_tipo: 'Tipo',
        texto_obrigatorio: 'Campo obrigatório',
        texto_selecione: 'Selecione',
        marcas: 'Lojas',
        cardapio: 'Cardápio',
        unidades: 'Unidades',
        promocoes: 'Combos',
        operacao: 'Operação',
        configuracao: 'Configuração',
        plataforma: 'Plataforma',
        selecao_obrigatoria: 'Selecione uma das opções de acesso',
        senha_caracteres_min: 'Senha deve conter no mínimo 8 caracteres.',
        senha_caracteres_max: 'Senha deve conter no máximo 32 caracteres.',
        senha_diferentes: 'As senhas digitadas não são iguais, favor verificar.',
        senha_alterada: 'Senha cadastrada com sucesso.',
        email_invalido: 'E-mail inválido',
        cpf_invalido: 'CPF inválido',
        usuario_caracteres_min: 'Usuário deve conter no mínimo 5 caracteres.',
        usuario_caracteres_max: 'Usuário deve conter no máximo 50 caracteres.',
        observacao: 'Observação',
        sair: 'Sair',
        home: 'Minha área',
        ola: 'Olá',
        role: 'Função',
        quantidade_maxima: 'Quantidade Máxima',
        recebimento_30_dias: 'Valores recebidos nos últimos 30 dias',
        campo_id: "Id",
        campo_url: "Url",
        campo_situacao: "Situação",
        texto_quantidade: "Quantidade",
        PRODUCT_ALREADY_REGISTERED: "Já existe um produto com este nome!",
        sim: "Sim",
        nao: "Não",
        //LOGIN
        entrar: 'Entrar',
        login_mensagem: 'Verifique se o nome de usuário ou a senha estão corretos.',
        alterar_senha: 'Alterar senha',
        recuperar_senha: 'Recuperar senha',
        usuario_login_recuperar_senha: 'Informe seu e-mail para recuperação.',
        usuarioLogin: 'Usuário',
        senhaLogin: 'Senha',
        USER_NOT_FOUND: 'Usuário não localizado',
        email_enviado: 'Uma mensagem com instruções foi enviado para o seu e-mail.',
        TOKEN_EXPIRED: 'Link para alteração de senha expirado. Repita o processo de recuperação de senha!',
        //PLATAFORMA
        gerenciamento_plataforma: 'Plataforma',
        gerenciamento_plataforma_popup_cliente: 'PopUp App Cliente',
        gerenciamento_plataforma_popup_entregador: 'PopUp App Entregador',
        gerenciamento_plataforma_alterado: 'Configurações realizadas com sucesso!',
        gerenciamento_plataforma_cancelamento: 'Limite para cancelamento de pedidos',
        gerenciamento_plataforma_descricao: 'Utilize uma imagem com a proporção de 3 x 4 (Largura x Altura)',
        gerenciamento_plataforma_codigo_indicacao: 'Mensagem de compartilhamento (digite @codigo@ para enviar o código)',
        gerenciamento_plataforma_home_brands: "Pesquisa de Lojas",
        gerenciamento_plataforma_home_tickets: 'Pesquisa de Ingressos',
        gerenciamento_plataforma_endereco: 'Endereço para navegar',
        gerenciamento_plataforma_codigo_postal: 'Código Postal Obrigatório',
        gerenciamento_plataforma_descricao_lojas: 'Descrição da Loja',
        gerenciamento_plataforma_descricao_pedidos: 'Descrição dos Pedidos',
        gerenciamento_plataforma_documentacao: 'Documentos da Loja',
        gerenciamento_plataforma_cnpjcpf: 'CNPJ / CPF',
        gerenciamento_plataforma_nif: 'NIF',
        //MOTIVOS DE CANCELAMENTO
        motivos_cancelamento: 'Motivos de Cancelamento',
        motivos_cancelamento_descricao: 'Descrição',
        motivos_cancelamento_editar: 'Motivo editado com sucesso!',
        motivos_cancelamento_cadastrar: 'Motivo cadastrado com sucesso!',
        motivos_cancelamento_excluir: 'Deseja realmente excluir este motivo?',
        REASON_HAS_LINKED_ORDERS: 'Motivo não pode ser excluído. Existem pedidos vinculado.',
        //DESTAQUES DE APLICATIVOS
        destaques_aplicativos: 'Destaques de Aplicativo',
        destaques_aplicativos_sem_destaque: 'Sem destaque',
        destaques_aplicativos_descricao: 'Descrição',
        destaques_aplicativos_inicio: 'Início',
        destaques_aplicativos_fim: 'Fim',
        destaques_aplicativos_editar: 'Destaque editado com sucesso!',
        destaques_aplicativos_cadastrar: 'Destaque cadastrado com sucesso!',
        destaques_aplicativos_excluir: 'Deseja realmente excluir este destaque?',
        //SEGMENTOS SUPERIORES
        CATEGORY_HAS_LINKED_CHILD: 'Existem segmentos vinculados a este.',
        gerenciamento_segmentos_superior_descricao: 'Descrição (limite de 20 caracteres)',
        gerenciamento_segmentos_superior: 'Segmentos Superiores',
        gerenciamento_segmentos_superior_editar: 'Editar Segmento',
        gerenciamento_segmentos_superior_editado: 'Segmento editado com sucesso!',
        gerenciamento_segmentos_superior_adicionar: 'Cadastrar Segmento',
        gerenciamento_segmentos_superior_cadastrado: 'Segmento cadastrado com sucesso!',
        gerenciamento_segmentos_superior_excluir: 'Realmente deseja excluir permanentemente esse segmento?',

        //SEGMENTOS
        CATEGORY_HAS_LINKED_BRANDS: 'Existem lojas vinculadas a esta marca.',
        gerenciamento_segmentos: 'Segmentos',
        gerenciamento_segmentos_editar: 'Editar Segmento',
        gerenciamento_segmentos_editado: 'Segmento editado com sucesso!',
        gerenciamento_segmentos_adicionar: 'Cadastrar Segmento',
        gerenciamento_segmentos_cadastrado: 'Segmento cadastrado com sucesso!',
        gerenciamento_segmentos_excluir: 'Realmente deseja excluir permanentemente esse segmento?',

        //MARCAS
        gerenciamento_categorias_superiores: 'Segmentos Superiores',
        CONFLICT_DESTROY_BRAND_WITH_RESTAURANTS_LINKED: 'Ainda existe unidade vinculada a está Loja.',
        gerenciamento_marcas: 'Lojas',
        gerenciamento_categorias: 'Segmentos',
        botao_cardapio: 'Produtos',
        botao_unidades: 'Unidades',
        botao_cupons: 'Cupons',
        botao_editar: 'Editar',
        botao_excluir: 'Excluir',
        nova_marca: 'Nova Loja',
        deletar_marcas: 'Realmente deseja excluir permanentemente essa Loja?',
        deletar_titulos: 'Você tem certeza?',
        deletar_sim: 'Sim',
        deletar_nao: 'Não',
        //MARCA
        titulo_marca: 'Loja',
        titulo_marca_adicionar: 'Nova Loja',
        titulo_marca_editar: 'Editar Loja',
        campo_nome_marca: 'Nome da Loja',
        categorias_marca: 'Segmento',
        marca_titulo: 'Loja',
        marca_nome_compania: 'Razão Social',
        marca_id: 'CNPJ',
        marca_nif: 'NIF',
        marca_id_estadual: 'Inscrição Estadual',
        marca_id_municipal: 'Inscrição Municipal',
        marca_editada: 'Loja editada com sucesso!',
        marca_cadastrada: 'Loja cadastrada com sucesso!',
        marca_taxas: 'Tabela de Taxas',
        marca_ativa: 'Loja Ativa',
        mostrar_preco_delivery_app: 'Taxa Entrega App',
        marca_banco_dados: 'Dados Bancários',
        marca_banco_nome: 'Banco',
        marca_banco_codigo: 'Código',
        marca_banco_agencia: 'Agência',
        marca_banco_conta: 'Conta',
        marca_banco_conta_digito: 'Digito',
        //OPCIONAIS
        opcionais_cardapios: 'Opcionais',
        opcionais_cardapios_grupo: 'Grupos',
        opcionais_cardapios_vincular_grupo: 'Grupo',
        opcionais_cardapios_novo_grupo: 'Novo Grupo',
        opcionais_cardapios_editado: 'Opção editada com sucesso!',
        opcionais_cardapios_editar: 'Editar opção',
        opcionais_cardapios_cadastrado: 'Opção cadastrada com sucesso!',
        opcionais_cardapios_adicionar: 'Adicionar opção',
        opcionais_cardapios_excluir: 'Realmente deseja excluir permanentemente esta opção?',
        //CATEGORIAS DE PRODUTOS
        produto_fisico: "Físico",
        produto_digital: "Digital",
        produto_ingresso: "Ingressos",
        Storable: "Físico",
        Digital: "Digital",
        Ticket: "Ingressos",
        //CARDAPIOS
        gerenciamento_cardapio: 'Produtos',
        gerenciamento_categorias_cardapios: 'Tipos de Produtos',
        gerenciamento_categorias_cardapios_editado: 'Tipo editada com sucesso!',
        gerenciamento_categorias_cardapios_editar: 'Editar tipo',
        gerenciamento_categorias_cardapios_cadastrado: 'Tipo cadastrado com sucesso!',
        gerenciamento_categorias_cardapios_adicionar: 'Adicionar tipo',
        gerenciamento_categorias_cardapios_excluir: 'Realmente deseja excluir permanentemente este tipo?',
        texto_extras: 'Extras',
        texto_combo: 'Opções',
        novo_item: 'Novo Item',
        //CARDAPIO
        cadastro_produto: 'Cadastro de Produtos',
        editar_produto: 'Editar Produto',
        campo_nome_item: 'Nome do Item',
        campo_descricao: 'Descrição',
        campo_local_evento: 'Local do Evento',
        campo_datahora_evento: 'Data e Hora',
        campo_tipo_ingresso: 'Tipo de Ingresso',
        campo_produzir: 'Produção para Entrega',
        campo_estoque: 'Estocavel',
        campo_esgotado: 'Esgotado',
        campo_bloqueado: 'Venda Bloqueada',
        campo_em_divulgacao: 'Em divulgação',
        campo_categoria: 'Categoria',
        campo_quantidade_extras: 'Limite de Extras',
        campo_valor: 'Valor',
        campo_valor_adicional: 'Valor Adicional',
        campo_tempo_preparacao: 'Tempo de Preparo',
        campo_moeda: 'Moeda',
        campo_taxa: 'Taxas e Impostos',
        campo_ingredientes: 'Composição',
        campo_retirada: 'Permitir Retirar',
        campo_valor_categoria: 'Valor da Categoria: R$ ',
        campo_arquivo: "Arquivo",
        campo_largura: 'Largura(cm)',
        campo_altura: 'Altura(cm)',
        campo_comprimento: 'Comprimento(cm)',
        campo_diametro: 'Diâmetro(cm)',
        campo_peso: 'Peso(g)',
        //EXTRAS
        cadastro_extra: 'Ingredientes Extras',
        quantidade_maxima_extra: 'Pedido Máximo',
        //COMBO
        cadastro_combo: 'Opções de Ingredientes',
        campo_item: 'Item',
        campo_opcao: 'Opção',
        grid_itens: 'Itens',
        mensagem_opcao_igual: 'Não é permitido registrar opções iguais',
        mensagem_combo: 'Item cadastrado com sucesso!',
        mensagem_editar_combo: 'Item editado com sucesso!',
        mensagem_excluir_combo: 'Realmente deseja excluir esse item?',
        //UNIDADES
        gerenciamento_unidades: 'Unidades',
        nova_unidade: 'Nova unidade',
        excluir_unidade: 'Realmente deseja excluir permanentemente essa unidade?',
        //UNIDADE
        adicionar_unidade: 'Nova Unidade',
        editar_unidade: 'Editar Unidade',
        campo_nome_unidade: 'Nome da Unidade',
        campo_zip: 'Código Postal (CEP etc)',
        campo_endereco: 'Endereço',
        campo_endereco_numero: 'Número',
        campo_complemento: 'Complemento',
        campo_bairro: 'Bairro',
        campo_cidade: 'Cidade/município',
        campo_estado: 'Estado',
        campo_responsavel: 'Responsável',
        descricao_responsavel: 'Escolha o Responsável',
        campo_area_atendimento: 'Área de Entrega (km)',
        campo_latitude: 'Latitude',
        campo_longitude: 'Longitude',
        descricao_moeda: 'Escolha a moeda',
        campo_aberta_24_horas: 'Aberto 24 horas',
        campo_horario: 'Horário de atendimento',
        campo_permite_retirar: 'Permite Retirar na Loja',
        campo_frete_correio: 'Frete por Correios',
        campo_loja_parada: 'Vendas Pausadas',
        campo_taxas_gratis: 'Entrega Grátis',
        campo_taxas: 'Taxa de Entrega',
        texto_comparativo: 'à',
        texto_tempo_entrega: 'Tempo de Entrega',
        campo_pagamentos: 'Método de Pagamento',
        campo_semana: 'Escolha o dia da semana',
        segunda: 'Segunda',
        terca: 'Terça',
        quarta: 'Quarta',
        quinta: 'Quinta',
        sexta: 'Sexta',
        sabado: 'Sábado',
        domingo: 'Domingo',
        campo_tipo_entrega: 'Selecione o Tipo',
        minutos: 'Minutos',
        horas: 'Horas',
        dias: 'Dias',
        texto_horario: 'Informe horário inicio e fim',
        texto_horario_fim: 'Horário fim deve ser maior que horário inicio',
        titulo_unidade: 'Unidade',
        unidade_cadastrada: 'Unidade cadastrada com sucesso!',
        unidade_editada: 'Unidade editada com sucesso!',
        //ESTOQUE
        estoque: 'Estoque da Unidade',
        estoque_digital: 'Estoque Digital',
        estoque_ingressos: 'Ingressos',
        Pending: 'Pendente',
        Sold: 'Vendido',
        Used: 'Utilizado',
        estoque_selecione_arquivo: 'Selecione um arquivo para inclusão.',
        quantidade_atual: 'Quantidade Atual',
        quantidade_nova: 'Ajustar Quantidade',
        estoque_atualizado: 'Estoque Atualizado com Sucesso!',
        estoque_digital_excluir: 'Deseja realmente excluir o item selecionado?',
        //GERENCIAMENTO MARCAS
        nova_categoria: 'Novo Segmento',
        //PROMOCAO
        gerenciamento_promocao: 'Combos',
        nova_promocao: 'Novo Combo',
        excluir_promocao: 'Realmente deseja excluir permanentemente essa Combo',
        //PROMOÇÃO
        cadastro_promocao: 'Cadastro de Combo',
        editar_promocao: 'Editar Combo',
        campo_marca: 'Loja',
        campo_produto: 'Produto',
        campo_quantidade: 'Quantidade',
        grid_produtos: 'Produtos',
        produto_minimo: 'Inclua ao menos um produto para prosseguir!',
        promocao_editado: 'Combo editado com sucesso!',
        promocao_cadastrado: 'Combo cadastrado com sucesso!',
        promocao_titulo: 'Combos',
        //CUPONS
        gerenciamento_cupons_titulo: 'Cupons',
        gerenciamento_cupons_editado: 'Cupom editado com sucesso!',
        gerenciamento_cupons_cadastrado: 'Cupom cadastrado com sucesso!',
        gerenciamento_cupons_excluir: 'Realmente deseja excluir permanentemente esse cupom?',
        gerenciamento_cupons: 'Cupons',
        gerenciamento_cupons_codigo: 'Código',
        gerenciamento_cupons_nome: 'Nome',
        gerenciamento_cupons_quantidade: 'Quantidade',
        gerenciamento_cupons_valor: 'Desconto',
        gerenciamento_cupons_valor_minimo: 'Valor Mínimo',
        gerenciamento_cupons_tipo_valor: 'Tipo do Valor',
        gerenciamento_cupons_tipo_valor_percentual: '%',
        gerenciamento_cupons_tipo_valor_financeiro: 'R$',
        gerenciamento_cupons_vigencia: 'Vigência',
        gerenciamento_cupons_validade: 'Validade',
        gerenciamento_cupons_limite_periodo: 'Limite',
        gerenciamento_cupons_tipo_periodo: 'Tipo de Período',
        gerenciamento_cupons_estabelecimentos: 'Estabelecimentos',
        gerenciamento_cupons_primeiras_compras: '1ª\'s Compras',
        gerenciamento_cupons_ultima_compra: 'Última compra (dias)',
        gerenciamento_cupons_indicar_usuarios: 'Indicações',
        gerenciamento_cupons_distancia_endereco: 'Distância (em KM)',
        gerenciamento_cupons_tipo_periodo_dias: 'Dia',
        gerenciamento_cupons_tipo_periodo_meses: 'Mês',
        gerenciamento_cupons_cadastro: 'Cadastro de Cupom',
        gerenciamento_cupons_editar: 'Editar Cupom',
        gerenciamento_cupons_frete_gratis: 'Frete Grátis',
        gerenciamento_cupons_taxa_cliente_gratis: 'Taxa do Cliente Grátis',
        gerenciamento_cupons_entrega_manual: 'Entrega Manual',
        past: 'Vencidos',
        future: 'Futuros',
        active: 'Ativos',
        //USUARIOS
        gerenciamento_usuario: 'Usuários',
        novo_usuario: 'Novo Usuário',
        excluir_usuario: 'Realmente deseja excluir permanentemente esse usuário? ',
        nova_senha_usuario: 'Realmente deseja criar uma nova senha para esse usuário? ',
        market_place_administrator: 'Administrador Market Place',
        account_manager: 'Gerente de Contas',
        stores_administrator: 'Administrador de Lojas',
        employee: 'Funcionário',
        //USUARIO
        usuario_perfil: 'Usuários',
        perfil: 'Perfil',
        cadastro_usuario: 'Cadastro de Usuário',
        editar_usuario: 'Editar Usuário',
        campo_nome: 'Nome',
        campo_usuario: 'Usuário',
        campo_cpf: 'CPF',
        campo_email: 'E-mail',
        campo_telefone: 'Telefone',
        campo_senha: 'Senha',
        campo_confirma_senha: 'Confirmar senha',
        acessos: 'Acessos',
        usuario_titulo: 'Usuários',
        usuario_editado: 'Usuário editado com sucesso!',
        usuario_cadastrado: 'Usuário cadastrado com sucesso!',
        usuario_nova_senha: 'Nova senha enviada para o email do usuário!',
        CPF_IN_USE_BY_ANOTHER_USER: 'CPF já está sendo utilizado por outro usuário!',
        //PAGAMENTOS
        pagamento: 'Meios de Pagamento',
        gerenciamento_pagamentos: 'Métodos de Pagamento',
        novo_pagamento: 'NOVO MÉTODO DE PAGAMENTO',
        excluir_pagamento: 'Realmente deseja excluir permanentemente esse método de pagamento?',
        //PAGAMENTO
        cadastro_pagamento: 'Cadastro de Método de Pagamento',
        editar_pagamento: 'Editar Método de Pagamento',
        campo_nome_pagamento: 'Nome do Método de Pagamento',
        configuracoes: 'Configurações',
        campo_dinheiro: 'Dinheiro',
        configuracoes_gateway: 'Pagamento pelo Aplicativo',
        campo_pagamento_credito: 'Cartão de Crédito',
        campo_pagamento_debito: 'Cartão de Débito',
        campo_pagamento_pix: 'PIX',
        pagamento_editado: 'Método de Pagamento editado com sucesso!',
        pagamento_cadastrado: 'Método de Pagamento cadastrado com sucesso!',
        pagamento_titulo: 'Método de Pagamento',
        //ENTREGADORES
        delivery_person: 'Entregador',
        //ENTREGADOR
        cadastro_entregador: 'Cadastro de Entregador',
        editar_entregador: 'Editar Entregador',
        entregador_cadastrado: 'Entregador cadastrado com sucesso!',
        entregador_editado: 'Entregador editado com sucesso!',
        entregador_titulo: 'Entregadores',
        deletar_produto: 'Realmente deseja excluir permanentemente esse Produto?',
        //CONTROLE DE PEPDIDOS
        cancelamento_selecionar: 'Selecione o motivo do cancelamento.',
        cancelamento_mensagem: 'Pedido cancelado com sucesso!',
        controle_pedidos: 'Controle de Pedidos',
        novo_pedido: 'Novos Pedidos',
        pedido_producao: 'Pedidos em Produção',
        pedido_liberado: 'Pedidos liberados',
        pedido_numero: 'Pedido',
        pedido_quantidade: 'Quantidade',
        pedido_liberado_quantity: 'Quantidade',
        pedido_quantidade_producao: 'Quantidade',
        //CONTROLE DE ENTREGAS
        controle_entrega: 'Controle de Entregas',
        pedido_enviado: 'Em Entrega ou Retirada',
        pedido_entregue: 'Pedidos Entregues',
        entregador: 'Entregador',
        troco: 'Troco para',
        entregador_mensagem: 'Por favor, informe um entregador.',
        entregador_mensagem_titulo: 'Atenção',
        //CONTROLE DE RECEBIMENTOS
        resumo_recebimento: 'Resumo de Recebimentos',
        controle_recebimentos: 'Controle de Recebimentos',
        valor_recebido: 'Valor Recebido',
        pedido_finalizado: 'Pedidos Finalizados',
        //VALORES RECEBIDOS
        valores_recebidos: 'Valores Recebidos',
        data_inicio: 'Data início',
        data_fim: 'Data Fim',
        pedidos_finalizados: 'Pedidos Finalizados',
        valor_recebimento: 'Valor dos Recebimentos',
        pesquisar: 'Pesquisar',
        tipo_pesquisa: 'Tipo de Pesquisa',
        tipo_numero: 'Número do Pedido',
        tipo_data: 'Data',
        pedido: 'Pedidos',
        //BATE-PAPO
        bate_papo: 'Chat',
        chat_mensagens_nao_lidas: 'Mensagens não lidas',
        cliente: 'Cliente',
        data: 'Data',
        situacao: 'Situação',
        open: 'Novo pedido',
        in_preparation: 'Em preparação',
        ready: 'Liberados',
        delivering: 'Entregando / Aguardando retirada',
        delivered: 'Entregue',
        closed: 'Fechado',
        canceled: 'Cancelado',
        EMAIL_IN_USE_BY_ANOTHER_USER: 'E-mail já cadastrado',
        USER_ALREADY_EXISTS: 'Usuário já existe',
        // IMAGE HELPER
        image_helper: 'Conversor de Imagens',
        image_load_error: 'Falha ao carregar a Imagem',
        image_load_size: 'A imagem deve ter a dimensão mínima de 200px de largura e 200px de altura.',


        //PAGAMENTOS
        taxas: 'Taxas',
        taxas_pagamentos: 'Métodos de Pagamento',
        nova_taxa: 'NOVO MÉTODO DE PAGAMENTO',
        excluir_taxa: 'Realmente deseja excluir permanentemente esse método de pagamento?',
        //TAXAS
        cadastro_taxa: 'Taxas',

        campo_taxa_grid: 'Taxas',
        campo_taxa_nome: 'Nome da Taxa',
        campo_inicio: 'Vigência',
        campo_fim: 'Validade',


        campo_taxa_valor: 'Teto de valor',
        campo_taxa_tipo: 'Tipo da taxa',
        campo_taxa_cliente: 'Taxa cliente',
        campo_taxa_marca: 'Taxa loja',

        percentage: 'Percentual',
        value: 'Valor',

        mensagem_taxa_igual: 'Não é permitido registrar opções iguais',
        mensagem_taxa: 'Item cadastrado com sucesso!',
        mensagem_editar_taxa: 'Item editado com sucesso!',
        mensagem_excluir_taxa: 'Realmente deseja excluir esse item?',

        //ENQUETES
        cadastro_enquete: 'Enquete',
        campo_enquete_perguntas: 'Perguntas',
        campo_enquete_pergunta: 'Pergunta',
        campo_enquete_resposta: 'Resposta',
        mensagem_enquete_igual: 'Não é permitido registrar opções iguais',
        mensagem_enquete: 'Item cadastrado com sucesso!',
        mensagem_editar_enquete: 'Item editado com sucesso!',
        mensagem_excluir_enquete: 'Realmente deseja excluir esse item?',
      }
    },
    eng: {
      languange: 'eng',
      values: {
        //gener
        EMAIL_IN_USE_BY_ANOTHER_USER: 'E-mail already registered',
        USER_ALREADY_EXISTS: 'User already exists',
        texto_adicionar: 'Add',
        texto_excluir: 'Delete',
        texto_nova_senha: 'New Password',
        texto_confirmar_senha: 'Confirm Password',
        texto_editar: 'EDIT',
        texto_upload: 'Upload',
        texto_enviar: 'Send',
        texto_salvar: 'SAVE',
        texto_cancelar: 'CANCEL',
        texto_voltar: 'Come Back',
        texto_acao: 'Action',
        texto_tipo: 'Type',
        texto_obrigatorio: 'Required field',
        texto_selecione: 'Select',
        marcas: 'Stores',
        cardapio: 'Menu',
        unidades: 'Restaurants',
        promocoes: 'Combos',
        operacao: 'Operation',
        configuracao: 'Configuration',
        selecao_obrigatoria: 'Select one of the access options',
        senha_caracteres_min: 'Password must contain at least 8 characters.',
        senha_caracteres_max: 'Password must contain a maximum of 32 characters.',
        senha_diferentes: 'The passwords entered are not the same, please check.',
        senha_alterada: 'Senha cadastrada com sucesso.',
        email_invalido: 'Invalid email',
        cpf_invalido: 'Invalid CPF',
        usuario_caracteres_min: 'User must contain at least 5 characters.',
        usuario_caracteres_max: 'User must contain a maximum of 50 characters.',
        observacao: 'Note',
        sair: 'Exit',
        home: 'My Place',
        ola: 'Hello',
        role: 'Role',
        quantidade_maxima: 'Max Quantity',
        recebimento_30_dias: 'Valores recebidos nos últimos 30 dias',
        campo_id: "Id",
        campo_url: "Url",
        campo_situacao: "Status",
        texto_quantidade: "Quantity",
        //LOGIN
        entrar: 'Login',
        login_mensagem: 'Check that the username or password is correct',
        usuarioLogin: 'User',
        alterar_senha: 'Alterar senha',
        recuperar_senha: 'Recuperar senha',
        usuario_login_recuperar_senha: '',
        senhaLogin: 'Password',
        USER_NOT_FOUND: 'User not Found',
        email_enviado: 'Um email com instruções foi enviado para o seu endereço.',
        TOKEN_EXPIRED: 'Link para alteração de senha expirado. Repita o processo de recuperação de senha!',
        //PLATAFORMA
        gerenciamento_plataforma: 'Plataforma',
        gerenciamento_plataforma_popup_cliente: 'PopUp App Cliente',
        gerenciamento_plataforma_popup_entregador: 'PopUp App Entregador',
        gerenciamento_plataforma_alterado: 'Configurações realizadas com sucesso!',
        gerenciamento_plataforma_cancelamento: 'Limite para cancelamento de pedidos',
        gerenciamento_plataforma_descricao: 'Utilize uma imagem com a proporção de 3 x 4 (Largura x Altura)',
        gerenciamento_plataforma_codigo_indicacao: 'Mensagem de compartilhamento (digite @codigo@ para enviar o código)',
        gerenciamento_plataforma_endereco: 'Endereço Obrigatório Navegação',
        gerenciamento_plataforma_home_tickets: 'Pesquisa por Ingressas na Home',
        gerenciamento_plataforma_codigo_postal: 'Código Postal Obrigatório',
        gerenciamento_plataforma_descricao_lojas: 'Descrição da Loja',
        gerenciamento_plataforma_descricao_pedidos: 'Descrição dos Pedidos',
        gerenciamento_plataforma_documentacao: 'Documentos da Loja',
        gerenciamento_plataforma_cnpjcpf: 'CNPJ / CPF',
        gerenciamento_plataforma_nif: 'NIF',
        //BRANDS
        CONFLICT_DESTROY_BRAND_WITH_RESTAURANTS_LINKED: 'There is still a unit linked to this brand.',
        gerenciamento_marcas: 'Stores Management',
        gerenciamento_categorias: 'Category Manegement',
        botao_cardapio: 'MENU',
        botao_unidades: 'UNITS',
        botao_editar: 'EDIT',
        botao_excluir: 'DELETE',
        nova_marca: 'NEW BRAND',
        deletar_marcas: 'Do you really want to permanently delete that tag?',
        deletar_titulos: 'Are you sure?',
        deletar_sim: 'Yes',
        deletar_nao: 'No',
        //BRAND
        titulo_marca: 'Brand',
        titulo_marca_adicionar: 'New Brand',
        titulo_marca_editar: 'Edit Brand',
        campo_nome_marca: 'NEW BRAND',
        categorias_marca: 'Category',
        marca_titulo: 'Brand',
        marca_nome_compania: 'Brand Company Name',
        marca_id: 'Brand Id',
        marca_nif: 'NIF',
        marca_id_estadual: 'Brand State Id',
        marca_id_municipal: 'Brand City Id',
        marca_editada: 'Brand edited successfully!',
        marca_cadastrada: 'Brand registered successfully!',
        marca_ativa: 'Active Brand',
        mostrar_preco_delivery_app: 'Show Delivery Price',
        marca_banco_dados: 'Dados Bancários',
        marca_banco_nome: 'Banco',
        marca_banco_codigo: 'Código',
        marca_banco_agencia: 'Agência',
        marca_banco_conta: 'Conta',
        marca_banco_conta_digito: 'Digito',
        //OPCIONAIS
        opcionais_cardapios: 'Opcionais',
        opcionais_cardapios_editado: 'Opção editada com sucesso!',
        opcionais_cardapios_editar: 'Editar opção',
        opcionais_cardapios_cadastrado: 'Opção cadastrada com sucesso!',
        opcionais_cardapios_adicionar: 'Adicionar opção',
        opcionais_cardapios_excluir: 'Realmente deseja excluir permanentemente esta opção?',
        //CATEGORIAS DE PRODUTOS
        produto_fisico: "Storable",
        produto_digital: "Digital",
        produto_ingresso: "Ticket",
        Storable: "Storable",
        Digital: "Digital",
        Ticket: "Ticket",
        //CARDAPIOS
        gerenciamento_cardapio: 'Menu Management',
        gerenciamento_categorias_cardapios: 'Product Categories',
        gerenciamento_categorias_cardapios_editado: 'Category edited successfully!',
        gerenciamento_categorias_cardapios_editar: 'Edit category',
        gerenciamento_categorias_cardapios_cadastrado: 'Category registered successfully!',
        gerenciamento_categorias_cardapios_adicionar: 'Add category',
        gerenciamento_categorias_cardapios_excluir: 'Do you really want to delete this category?',
        texto_extras: 'Extras',
        texto_combo: 'Options',
        novo_item: 'New Item',
        //CARDAPIO
        cadastro_produto: 'Product Registration',
        editar_produto: 'Edit Product',
        campo_nome_item: 'Item Name',
        campo_descricao: 'Description',
        campo_local_evento: 'Local do Evento',
        campo_datahora_evento: 'Data e Hora',
        campo_tipo_ingresso: 'Tipo de Ingresso',
        campo_produzir: 'Produce before Delivery',
        campo_estoque: 'Stock Control',
        campo_esgotado: 'Sold Out',
        campo_bloqueado: 'Blocked',
        campo_categoria: 'Category',
        campo_quantidade_extras: 'Max Extra Ingredients',
        campo_valor: 'Value',
        campo_valor_adicional: 'Aditional Value',
        campo_tempo_preparacao: 'Setup Time',
        campo_moeda: 'Coin',
        campo_taxa: 'TAX',
        campo_ingredientes: 'Composition',
        campo_retirada: 'Allows to withdraw',
        campo_valor_categoria: 'Category Price US$: ',
        campo_arquivo: "Base File",
        campo_largura: 'Largura',
        campo_altura: 'Altura',
        campo_comprimento: 'Comprimento',
        campo_diametro: 'Diâmetro',
        campo_peso: 'Peso',
        //EXTRAS
        cadastro_extra: 'Extras Registration',
        quantidade_maxima_extra: 'Max Quantity',
        //COMBO
        cadastro_combo: 'Options Registration',
        campo_item: 'Item',
        campo_opcao: 'Option',
        grid_itens: 'Items',
        mensagem_opcao_igual: 'It is not allowed to register equal options',
        mensagem_combo: 'Item successfully registered!',
        mensagem_editar_combo: 'Item successfully edited!',
        mensagem_excluir_combo: 'Do you really want to delete that item?',
        //UNIDADES
        gerenciamento_unidades: 'Unit Management',
        nova_unidade: 'New unit',
        excluir_unidade: 'Do you really want to permanently delete that drive?',
        //UNIDADE
        adicionar_unidade: 'New unit',
        editar_unidade: 'Edit Unit',
        campo_nome_unidade: 'Unit Name',
        campo_zip: 'ZIP CODE',
        campo_endereco: 'Address',
        campo_endereco_numero: 'Number',
        campo_complemento: 'Complement',
        campo_bairro: 'District',
        campo_cidade: 'City / County',
        campo_estado: 'State',
        campo_responsavel: 'Owner',
        descricao_responsavel: 'Choose owner',
        campo_area_atendimento: 'Service Area (km)',
        campo_latitude: 'Latitude',
        campo_longitude: 'Longitude',
        descricao_moeda: 'Choose currency',
        campo_aberta_24_horas: 'Aberto 24 horas',
        campo_horario: 'Opening hours',
        campo_permite_retirar: 'Take Away',
        campo_frete_correio: 'Frete por Correios',
        campo_loja_parada: 'Vendas Pausadas',
        campo_taxas_gratis: 'Entrega Grátis',
        campo_taxas: 'Delivery fee',
        texto_comparativo: 'to',
        texto_tempo_entrega: 'Delivery Time',
        campo_pagamentos: 'Payment options',
        campo_semana: 'Choose the day of the week',
        segunda: 'Monday',
        terca: 'Tuesday',
        quarta: 'Wednesday',
        quinta: 'Thursday',
        sexta: 'Friday',
        sabado: 'Saturday',
        domingo: 'Sunday',
        campo_tipo_entrega: 'Choose type',
        minutos: 'Minutes',
        horas: 'Hours',
        dias: 'Days',
        texto_horario: 'Enter start and end times',
        texto_horario_fim: 'End time must be greater than start time',
        titulo_unidade: 'Unit',
        unidade_cadastrada: 'Unit successfully registered!',
        unidade_editada: 'Unit successfully edited!',
        //ESTOQUE
        estoque: 'Unity Stock',
        estoque_digital: 'Digital Stock',
        estoque_ingressos: 'Ingressos',
        Pending: 'Pending',
        Sold: 'Sold',
        Used: 'Used',
        estoque_selecione_arquivo: 'Selecione um arquivo para inclusão.',
        quantidade_atual: 'Actual Quantity',
        quantidade_nova: 'New Quantity',
        estoque_atualizado: 'Stock successfully edited!',
        estoque_digital_excluir: 'Deseja realmente excluir o item selecionado?',
        //GERENCIAMENTO MARCAS
        nova_categoria: 'New Category',
        //PROMOCAO
        gerenciamento_promocao: 'Combos Management',
        nova_promocao: 'New Combos',
        excluir_promocao: 'Do you really want to permanently delete this Combos',
        //PROMOÇÃO
        cadastro_promocao: 'Combos Registration',
        editar_promocao: 'Edit Combos',
        campo_marca: 'Brand',
        campo_produto: 'Product',
        campo_quantidade: 'Amount',
        grid_produtos: 'Products',
        produto_minimo: 'Include at least one product to proceed!',
        promocao_editado: 'Combos successfully edited!',
        promocao_cadastrado: 'Combos successfully registered!',
        promocao_titulo: 'Specials',
        //CUPONS
        gerenciamento_cupons_titulo: 'Cupons',
        gerenciamento_cupons_editado: 'Cupom editado com sucesso!',
        gerenciamento_cupons_cadastrado: 'Cupom cadastrado com sucesso!',
        gerenciamento_cupons_excluir: 'Realmente deseja excluir permanentemente esse cupom?',
        gerenciamento_cupons: 'Cupons',
        gerenciamento_cupons_codigo: 'Código',
        gerenciamento_cupons_nome: 'Nome',
        gerenciamento_cupons_quantidade: 'Quantidade',
        gerenciamento_cupons_valor: 'Desconto',
        gerenciamento_cupons_valor_minimo: 'Valor Mínimo',
        gerenciamento_cupons_tipo_valor: 'Tipo do Valor',
        gerenciamento_cupons_tipo_valor_percentual: '%',
        gerenciamento_cupons_tipo_valor_financeiro: 'R$',
        gerenciamento_cupons_vigencia: 'Vigência',
        gerenciamento_cupons_validade: 'Validade',
        gerenciamento_cupons_limite_periodo: 'Limite de Aplicação',
        gerenciamento_cupons_tipo_periodo: 'Tipo de Período',
        gerenciamento_cupons_estabelecimentos: 'Estabelecimentos',
        gerenciamento_cupons_primeiras_compras: 'Primeiras compras',
        gerenciamento_cupons_ultima_compra: 'Dias da última compra',
        gerenciamento_cupons_indicar_usuarios: 'Usuários indicados',
        gerenciamento_cupons_distancia_endereco: 'Distância do endereço (em km)',
        gerenciamento_cupons_tipo_periodo_dias: 'Dia',
        gerenciamento_cupons_tipo_periodo_meses: 'Mês',
        gerenciamento_cupons_cadastro: 'Cadastro de Cupom',
        gerenciamento_cupons_editar: 'Editar Cupom',
        gerenciamento_cupons_frete_gratis: 'Frete Grátis',
        gerenciamento_cupons_taxa_cliente_gratis: 'Taxa do Cliente Grátis',
        gerenciamento_cupons_entrega_manual: 'Entrega Manual',
        past: 'Vencidos',
        future: 'Futuros',
        active: 'Ativos',
        //USUARIOS
        gerenciamento_usuario: 'User Management',
        novo_usuario: 'New User',
        excluir_usuario: 'Do you really want to permanently delete that user?',
        nova_senha_usuario: 'Do you really want to create a new password for this user? ',
        market_place_administrator: 'Market Place Administrator',
        account_manager: 'Account Manager',
        stores_administrator: 'Stores Administrator',
        employee: 'Employee',
        //USUARIO
        usuario_perfil: 'Users',
        perfil: 'Profile',
        cadastro_usuario: 'User registration',
        editar_usuario: 'Edit User',
        campo_nome: 'Name',
        campo_usuario: 'User',
        campo_cpf: 'Brazilian ID (CPF)',
        campo_email: 'E-mail',
        campo_telefone: 'Phone',
        campo_senha: 'Password',
        campo_confirma_senha: 'Confirm password',
        acessos: 'Access',
        usuario_titulo: 'Users',
        usuario_editado: 'User edited successfully!',
        usuario_cadastrado: 'User successfully registered!',
        usuario_nova_senha: 'New password sent to user email!',
        CPF_IN_USE_BY_ANOTHER_USER: 'CPF já está sendo utilizado por outro usuário!',
        //PAGAMENTOS
        pagamento: 'Payment Options',
        gerenciamento_pagamentos: 'Payment Method Management',
        novo_pagamento: 'NEW PAYMENT METHOD',
        excluir_pagamento: 'Do you really want to permanently delete this payment method?',
        //PAGAMENTO
        cadastro_pagamento: 'Payment Method Registration',
        editar_pagamento: 'Edit Payment Method',
        campo_nome_pagamento: 'Payment Method Name',
        configuracoes: 'Configuration',
        campo_dinheiro: 'Request Change',
        configuracoes_gateway: 'Online Payment',
        campo_pagamento_credito: 'Credit Card',
        campo_pagamento_debito: 'Debit',
        campo_pagamento_pix: 'PIX',
        pagamento_editado: 'Payment Method successfully edited!',
        pagamento_cadastrado: 'Payment Method successfully registered!',
        pagamento_titulo: 'Payment Method',
        //ENTREGADORES
        delivery_person: 'Deliveryman',
        //ENTREGADOR
        cadastro_entregador: 'Deliveryman Registration',
        editar_entregador: 'Edit Delivery Man',
        entregador_cadastrado: 'Delivery man registered successfully!',
        entregador_editado: 'Delivery man edited successfully!',
        entregador_titulo: 'Delivery Man',
        deletar_produto: 'Do you really want to permanently delete that Product?',
        //CONTROLE DE PEPDIDOS
        controle_pedidos: 'Order Control',
        novo_pedido: 'New Requests',
        pedido_producao: 'Production Orders',
        pedido_liberado: 'Released Orders',
        pedido_quantidade: 'Quantity',
        pedido_liberado_quantity: 'Quantity',
        pedido_quantidade_producao: 'Quantity',
        pedido_numero: 'Requests',
        //CONTROLE DE ENTREGAS
        controle_entrega: 'Delivery Control',
        pedido_enviado: 'Waiting for Delivery/Pick Up',
        pedido_entregue: 'Orders Delivered',
        entregador: 'Delivery Man',
        troco: 'Change',
        entregador_mensagem: 'Please inform a delivery person.',
        entregador_mensagem_titulo: 'Attention',
        //CONTROLE DE RECEBIMENTOS
        resumo_recebimento: 'Receipts Summary',
        controle_recebimentos: 'Receivement Control',
        valor_recebido: 'Amount received',
        pedido_finalizado: 'Completed Orders',
        //VALORES RECEBIDOS
        valores_recebidos: 'Amounts Received',
        data_inicio: 'Start Date',
        data_fim: 'End Date',
        pedidos_finalizados: 'Completed Orders',
        valor_recebimento: 'Amount of Receipts',
        pesquisar: 'Search',
        tipo_pesquisa: 'Search Type',
        tipo_numero: 'Order Number',
        tipo_data: 'Date',
        pedido: 'Orders',
        //BATE-PAPO
        bate_papo: 'Chat',
        chat_mensagens_nao_lidas: 'Not ready messages',
        cliente: 'Client',
        data: 'Date',
        situacao: 'Situation',
        open: 'New',
        in_preparation: 'In Preparation',
        ready: 'Ready',
        delivering: 'Delivering',
        delivered: 'Delivered',
        closed: 'CLosed',
        canceled: 'Canceled',
        // IMAGE HELPER
        image_helper: 'Image Converter',
        image_load_error: 'Failed to load image',
        image_load_size: 'The image must be at least 300px wide and 300px high. ',
      }
    }
  };

  constructor() { }

  translate(key: string): string {
    if (this.dictionary[this.language] != null) {
      return this.dictionary[this.language].values[key];
    }
  }

  setLanguage(language) {
    this.language = language;
  }
}

export class TranslationSet {
  public languange: string;
  public values: { [key: string]: string } = {};
}
