<app-toolbar></app-toolbar>
<div class="container">

  <div class="center">
    <image-helper (convertedImage)="getImage($event)"
                  [croppedImage]="imageSecureUrl"></image-helper>
  </div>

  <div class="center">
    <span class="error"
          *ngIf="!image && !imageId && isSubmitted">
      {{'texto_obrigatorio' | translate}}
    </span>
  </div>

  <form class="col s6"
        [formGroup]="produtoForm"
        novalidate
        (ngSubmit)="onSubmit(produtoForm)">

    <div class="row">
      <div class="input-field col s6"
           style="display: flex; flex-direction: column;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_categoria' | translate}}
        </span>

        <div class="input-field inline"
             style="margin-top: 0px; margin-left: 0;">
          <select material-select
                  (change)="selecionarCategoria()"
                  id="categoria"
                  formControlName="categoria">
            <option value=""
                    disabled
                    selected>{{'texto_selecione' | translate}}</option>
            <option *ngFor="let categoria of categorias"
                    [ngValue]="categoria._id">{{ categoria.name }}</option>

          </select>
          <div style="margin-top: 0.5rem;">
            <span class="error"
                  *ngIf="produtoForm.controls['categoria'].invalid && 
      (produtoForm.controls['categoria'].touched || isSubmitted)">
              {{'texto_obrigatorio' | translate}}
            </span>
          </div>
        </div>
      </div>

      <div class="input-field col s6"
           style="display: flex; flex-direction: column;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'destaques_aplicativos' | translate}}
        </span>

        <div class="input-field inline"
             style="margin-top: 0px; margin-left: 0;">
          <select material-select
                  id="destaque"
                  formControlName="destaque">
            <option value=""
                    selected>{{'destaques_aplicativos_sem_destaque' | translate}}</option>
            <option *ngFor="let destaque of destaques"
                    [ngValue]="destaque._id">{{ destaque.name }} ({{ destaque.start_date | date: "dd/MM/yyyy" }} - {{
              destaque.end_date | date: "dd/MM/yyyy" }})</option>

          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s6">
        <input id="nomeProduto"
               type="text"
               formControlName="nomeProduto">
        <label for="nomeProduto"
               [class.active]="modoEdicao">{{'campo_nome_item' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['nomeProduto'].invalid && 
        (produtoForm.controls['nomeProduto'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s6">
        <input id="descricao"
               type="text"
               formControlName="descricao">
        <label for="descricao"
               [class.active]="modoEdicao">{{'campo_descricao' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['descricao'].invalid && 
        (produtoForm.controls['descricao'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div *ngIf="categoriaSelecionada?.type === 'Ticket'"
         class="row">
      <div class="input-field col s3">
        <input id="datahoraevento"
               type="datetime-local"
               formControlName="datahoraevento">
        <label for="datahoraevento"
               [class.active]="true">{{'campo_datahora_evento' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['datahoraevento'].invalid && (produtoForm.controls['datahoraevento'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s4">
        <input id="localevento"
               type="text"
               formControlName="localevento">
        <label for="localevento"
               [class.active]="modoEdicao">{{'campo_local_evento' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['localevento'].invalid && (produtoForm.controls['localevento'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s3">
        <input id="tipoingresso"
               type="text"
               formControlName="tipoingresso">
        <label for="tipoingresso"
               [class.active]="modoEdicao">{{'campo_tipo_ingresso' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['tipoingresso'].invalid && (produtoForm.controls['tipoingresso'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field col s2"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_em_divulgacao' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="divulgacao" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
    </div>

    <div *ngIf="categoriaSelecionada?.type === 'Storable'"
         class="row"
         style="margin-bottom: -5rem;">
      <div class="input-field col s2">
        <input id="maximo_extras"
               type="number"
               formControlName="maximo_extras">
        <label for="maximo_extras"
               [class.active]="modoEdicao">{{'campo_quantidade_extras' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['maximo_extras'].invalid && (produtoForm.controls['maximo_extras'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
      <div class="input-field col s3">
        <input id="tempo_preparacao"
               type="number"
               formControlName="tempo_preparacao">
        <label for="tempo_preparacao"
               [class.active]="modoEdicao">{{'campo_tempo_preparacao' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['tempo_preparacao'].invalid && (produtoForm.controls['tempo_preparacao'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>

      <div class="input-field inline col s3"
           style="margin-top: 15px;">
        <select materialize="material_select"
                class="initialized ng-untouched ng-pristine ng-valid"
                id="tipo_tempo_preparacao"
                formControlName="tipo_tempo_preparacao">
          <option value=""
                  disabled
                  selected>{{'campo_tipo_entrega' | translate}}</option>
          <option value="minute">{{'minutos' | translate}}</option>
          <option value="hour">{{'horas' | translate}}</option>
          <option value="day">{{'dias' | translate}}</option>
        </select>
      </div>
    </div>

    <div *ngIf="categoriaSelecionada?.type !== 'Ticket'"
         class="row"
         style="margin-bottom: -5rem; margin-top: 2rem;">
      <div class="input-field col s3"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_produzir' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="produzir" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div class="input-field col s2"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_esgotado' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="esgotado" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div class="input-field col s3"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_bloqueado' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="bloqueado" />
              <span></span>
            </label>
          </div>
        </span>
      </div>
      <div class="input-field col s2"
           style="display: flex;flex-direction: column;margin-top: -11px;">
        <span class="labelSelect"
              style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
          {{'campo_estoque' | translate}}
          <div style="margin-top: 13px;">
            <label>
              <input type="checkbox"
                     formControlName="estoque" />
              <span></span>
            </label>
          </div>
        </span>
      </div>

    </div>

    <div *ngIf="categoriaSelecionada?.type === 'Digital' && !produtoForm.controls['estoque'].value"
         class="row">
      <div class="input-field col s1"
           style="margin-right: 10px;">

        <div class="btn-small btnUpload file-field input-field">
          <span>
            Enviar
          </span>
          <input type="file"
                 (change)="selecionarArquivoBase($event.target.files)">
        </div>
      </div>
      <div class="input-field col s10">
        <input id="arquivo"
               disabled
               type="text"
               formControlName="arquivo">
        <label for="arquivo"
               [class.active]="modoEdicao">{{'campo_arquivo' | translate}}</label>
      </div>


    </div>

    <!-- Ingredientes -->
    <div *ngIf="categoriaSelecionada?.type === 'Storable'">
      <div formArrayName="ingredientes">
        <label class="labelSelect"
               style="margin-left: 0rem;">{{'campo_ingredientes' | translate}}</label>
        <label class="labelSelect"
               style="margin-left: 31rem;margin-top: -25px;">{{'campo_retirada' | translate}}</label>

        <div *ngFor="let ingrediente of ingredientes.controls; let i=index"
             [formGroup]="ingrediente"
             style="margin-top: -1rem;">
          <div class="row linha">
            <div class="input-field col s6">
              <input id="name"
                     type="text"
                     formControlName="name">
            </div>

            <div class="input-field col s2">
              <label>
                <input type="checkbox"
                       formControlName="pullable" />
                <span></span>
              </label>
            </div>

            <div class="input-field col s2">
              <a *ngIf="i === ingredientes.length - 1"
                 class="btn-floating"
                 (click)="incluirIngredientes()"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_adicionar' | translate}}">
                <i class="material-icons">add</i>
              </a>
              <a *ngIf="i > 0"
                 class="btn-floating btn-remove"
                 (click)="removerIngredientes(i)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}">
                <i class="material-icons">cancel</i>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="form-group col s12 m7"
         style="margin-top: 1rem;">
      <div formArrayName="valores">
        <div *ngFor="let valor of valores.controls; let i=index"
             [formGroup]="valor">
          <div class="row">
            <div class="input-field col s6"
                 style="margin-right: 10px;">
              <input id="value"
                     type="number"
                     formControlName="value">
              <label for="value"
                     [class.active]="modoEdicao">{{'campo_valor' | translate}}</label>
              <span class="error"
                    style="margin-top: 0.5rem;"
                    *ngIf="valores.controls[i].controls.value.invalid && obrigarPreco &&
                (valores.controls[i].controls.value.touched || isSubmitted)">
                {{'texto_obrigatorio' | translate}}
              </span>
            </div>

            <div *ngIf="moedasCombo.length !== 1"
                 class="input-field col s3"
                 style="display: flex;
            flex-direction: column;
            margin-top: -11px;
            padding: 0;">
              <span class="labelSelect"
                    style="margin-left: 0;margin-bottom: 3px;margin-right: 10px;display: block;">
                {{'campo_moeda' | translate}}
              </span>

              <div class="input-field inline"
                   style="margin-top: 0px;
              margin-left: 0;">
                <select material-select
                        id="currency"
                        formControlName="currency"
                        (change)="selecionaMoeda(valores.controls[i].controls.currency.value)">
                  <option value=""
                          disabled
                          selected>{{'texto_selecione' | translate}}</option>
                  <option *ngFor="let moeda of moedasCombo"
                          [ngValue]="moeda._id"
                          [disabled]="moedasSelecionadas.includes(moeda._id)">
                    {{ moeda.name }}
                  </option>
                </select>

                <div style="margin-top: 0.5rem;">
                  <span class="error"
                        *ngIf="valores.controls[i].controls.currency.invalid && obrigarPreco &&
                  (valores.controls[i].controls.currency.touched || isSubmitted)">
                    {{'texto_obrigatorio' | translate}}
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="moedasCombo.length !== 1"
                 class="input-field col s2">
              <a *ngIf="i === valores.length - 1"
                 class="btn-floating"
                 (click)="incluirValores()"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_adicionar' | translate}}"
                 style="margin-top: 0;">
                <i class="material-icons">add</i>
              </a>

              <a *ngIf="i > 0"
                 class="btn-floating btn-remove"
                 (click)="removerValores(i)"
                 data-toggle="tooltip"
                 data-placement="bottom"
                 title="{{'texto_excluir' | translate}}"
                 style="margin-top: 0;">
                <i class="material-icons">cancel</i>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s6"
           style="margin-right: 10px;">
        <input id="taxas"
               type="number"
               formControlName="taxas">
        <label for="taxas"
               [class.active]="modoEdicao">{{'campo_taxa' | translate}}</label>
        <span class="error"
              *ngIf="produtoForm.controls['taxas'].invalid && 
        (produtoForm.controls['taxas'].touched || isSubmitted)">
          {{'texto_obrigatorio' | translate}}
        </span>
      </div>
    </div>

    <div *ngIf="categoriaSelecionada?.type === 'Storable'"
         class="row">
      <div class="input-field col s2">
        <input id="largura"
               type="number"
               formControlName="largura">
        <label for="largura"
               [class.active]="modoEdicao">{{'campo_largura' | translate}}</label>
      </div>
      <div class="input-field col s2">
        <input id="altura"
               type="number"
               formControlName="altura">
        <label for="altura"
               [class.active]="modoEdicao">{{'campo_altura' | translate}}</label>
      </div>

      <div class="input-field col s2">
        <input id="comprimento"
               type="number"
               formControlName="comprimento">
        <label for="comprimento"
               [class.active]="modoEdicao">{{'campo_comprimento' | translate}}</label>
      </div>
      <div class="input-field col s2">
        <input id="diametro"
               type="number"
               formControlName="diametro">
        <label for="diametro"
               [class.active]="modoEdicao">{{'campo_diametro' | translate}}</label>
      </div>

      <div class="input-field col s2">
        <input id="peso"
               type="number"
               formControlName="peso">
        <label for="peso"
               [class.active]="modoEdicao">{{'campo_peso' | translate}}</label>
      </div>
    </div>

    <!-- Buttons -->
    <div class="row center">
      <button class="btn btnCancelar"
              id="cancelar"
              (click)="cancelar()"
              type="button">
        {{'texto_cancelar' | translate}}
      </button>

      <button class="btn btnSalvar"
              id="salvar"
              type="submit"
              [disabled]="uploadImage">
        {{'texto_salvar' | translate}}
      </button>
    </div>
  </form>

</div>