import { HttpClientModule } from '@angular/common/http';
import { CategoriasService } from '../../../services/categorias.service';
import { TokenService } from '../../../services/token.service';
import { FormGroup, FormBuilder, Validators, FormArray, RequiredValidator } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {
  isSubmitted = false;
  @Output() mudaItems = new EventEmitter();
  categoriaForm: FormGroup;
  idCategoria: string;
  idMarca: string;
  url = null;
  user = this.tokenService.GetPayLoad();
  teste = this.tokenService.GetPayLoad();
  modoEdicao = false;
  produtoSubmitted = false;
  uploadImage = false;
  image: any;
  imageId: string;
  imageUrl: string;
  imageSecureUrl: string;
  marcas: any;
  produtos: any;
  moedasCombo: any;
  produtosInclusos = [];
  produtosQuantidade = [];
  moedasSelecionadas = [];

  constructor(
    private translatePipe: TranslatePipe,
    private fb: FormBuilder,
    private categoriasService: CategoriasService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.verificaPermissao();

    this.init();

    this.route.paramMap.subscribe(params => {
      this.idCategoria = params.get('idCategoria');
      this.idMarca = params.get('idMarca');

      if (this.idCategoria) {
        this.modoEdicao = true;
        this.carregaCampos();
      }
    });

  }

  verificaPermissao() {
    if (!this.user.accesses.special_offers) {
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
      if (this.user.accesses.operations) {
        this.router.navigate(['admin/pedidos']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }

  init() {
    this.categoriaForm = this.fb.group({
      nomeItem: ['', Validators.required],
      tipo: ['', Validators.required],
      preco: [''],
      tempo_preparacao: [''],
      tipo_tempo_preparacao: ['']
    });
  }

  getImage(event) {
    this.image = event;
  }

  onSubmit(form: FormGroup) {
    this.isSubmitted = true;
    if (form.valid && (this.image || this.imageId)) {


      if (this.image) {
        this.categoriasService.uploadImages(this.image).subscribe(res => {
          this.salvar({
            name: this.categoriaForm.value.nomeItem,
            type: this.categoriaForm.value.tipo,
            price: (this.categoriaForm.value.preco ? this.categoriaForm.value.preco : undefined),
            setup_time: (this.categoriaForm.value.tempo_preparacao ? this.categoriaForm.value.tempo_preparacao : undefined),
            setup_time_type: (this.categoriaForm.value.tipo_tempo_preparacao ? this.categoriaForm.value.tipo_tempo_preparacao : undefined),
            belongs_to: 'Items',
            brand: this.idMarca,
            image: {
              id: res.public_id,
              url: res.url,
              secure_url: res.secure_url
            }
          });
        });
      }
      else {
        this.salvar({
          name: this.categoriaForm.value.nomeItem,
          type: this.categoriaForm.value.tipo,
          price: (this.categoriaForm.value.preco ? this.categoriaForm.value.preco : undefined),
          setup_time: (this.categoriaForm.value.tempo_preparacao ? this.categoriaForm.value.tempo_preparacao : undefined),
          setup_time_type: (this.categoriaForm.value.tipo_tempo_preparacao ? this.categoriaForm.value.tipo_tempo_preparacao : undefined),
          belongs_to: 'Items',
          brand: this.idMarca,
          image: {
            id: this.imageId,
            url: this.imageUrl,
            secure_url: this.imageSecureUrl
          }
        });
      }
    }
  }

  salvar(categoria) {
    if (this.idCategoria) {
      this.categoriasService.editarCategoriaItem(this.idCategoria, categoria).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('gerenciamento_categorias_cardapios'), this.translatePipe.transform('gerenciamento_categorias_cardapios_editado'), 'success').then(() => {
            this.router.navigate([`admin/categorias/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('gerenciamento_categorias_cardapios'), error.error.message, 'error');
        }
      );
    } else {
      this.categoriasService.salvaCategoria(categoria).subscribe(
        res => {
          Swal.fire(this.translatePipe.transform('gerenciamento_categorias_cardapios'), this.translatePipe.transform('gerenciamento_categorias_cardapios_cadastrado'), 'success').then(() => {
            this.router.navigate([`admin/categorias/marca/${this.idMarca}`]);
          });
        },
        error => {
          Swal.fire(this.translatePipe.transform('gerenciamento_categorias_cardapios'), error.error.message, 'error');
        }
      );
    }
  }

  cancelar() {
    this.router.navigate([`admin/categorias/marca/${this.idMarca}`]);
  }

  // Editar
  carregaCampos() {
    this.isSubmitted = true;
    this.categoriasService.obterCategoria(this.idCategoria).subscribe(res => {
      this.categoriaForm.controls.nomeItem.setValue(res.name);
      this.categoriaForm.controls.tipo.setValue(res.type ? res.type : '');
      this.categoriaForm.controls.preco.setValue(res.price);
      this.categoriaForm.controls.tempo_preparacao.setValue(res.setup_time);
      this.categoriaForm.controls.tipo_tempo_preparacao.setValue(res.setup_time_type ? res.setup_time_type : '');

      this.imageId = res.image?.id;
      this.imageUrl = res.image?.url;
      this.imageSecureUrl = res.image?.secure_url;
      this.url = res.image.url;
    });
    this.categoriaForm.updateValueAndValidity();
  }
}
