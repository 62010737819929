import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { PedidosService } from '../../services/pedidos.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {
  pedidos = [];
  pedidosNovos = [];
  pedidosEmProducao = [];
  pedidosLiberados = [];
  tempoAtualizacao: any = 60;
  modalCancelamento: boolean = false;
  pedidoCancelamento: any;

  user = this.tokenService.GetPayLoad();

  constructor(
    private tokenService: TokenService,
    private fb: FormBuilder,
    private pedidosService: PedidosService,
    private router: Router,
    private route: ActivatedRoute,
    private translatePipe: TranslatePipe,
  ) { }

  ngOnInit() {

    this.verificaPermissao();

    this.filtrarPedidos();

    Observable.timer(0, 1000)
      .takeWhile(() => true) // Apenas com o componente ativo
      .subscribe(() => {
        this.regredirTempo();
      });

  }

  regredirTempo() {
    this.tempoAtualizacao = this.tempoAtualizacao - 1;
    if (this.tempoAtualizacao <= 0) {
      this.filtrarPedidos();
    }
  }

  atualizarTela() {
    this.filtrarPedidos();
  }

  verificaPermissao() {
    if (!this.user.accesses.operations) {
      if (this.user.accesses.special_offers) {
        this.router.navigate(['admin/promocoes']);
      }
      if (this.user.accesses.brands) {
        this.router.navigate(['admin/marcas']);
      }
      if (this.user.accesses.configurations) {
        this.router.navigate(['admin/gerenciamento-usuarios']);
      }
    }
  }


  filtrarPedidos() {
    this.pedidosService.obterPedidos().subscribe(res => {
      this.pedidos = res;

      this.pedidosNovos = [];
      this.pedidosEmProducao = [];
      this.pedidosLiberados = [];

      this.pedidos.forEach(pedido => {
        switch (pedido.status) {
          case 'open':
            this.pedidosNovos.push(pedido);
            break;
          case 'in_preparation':
            this.pedidosEmProducao.push(pedido);
            break;
          case 'ready':
            this.pedidosLiberados.push(pedido);
            break;
        }
      });

      this.tempoAtualizacao = 60;
    });
  }

  mudaStatusPedido(_id: string) {
    this.pedidos.map(pedido => {
      if (pedido._id === _id) {
        switch (pedido.status) {
          case 'open':
            let statusPreparation = { status: 'in_preparation' };
            this.pedidosService.trocarStatus(_id, statusPreparation).subscribe(res => {
              this.filtrarPedidos();
            });
            break;
          case 'in_preparation':
            let statusReady = { status: 'ready' };
            this.pedidosService.trocarStatus(_id, statusReady).subscribe(res => {
              this.filtrarPedidos();
            });
            break;
        }
      }
    });
  }

  mudaStatusPedidoRetorna(_id: string) {
    this.pedidos.map(pedido => {
      if (pedido._id === _id) {
        switch (pedido.status) {
          case 'in_preparation':
            let statusOpen = { status: 'open' };
            this.pedidosService.trocarStatus(_id, statusOpen).subscribe(res => {
              this.filtrarPedidos();
            });
            break;
          case 'ready':
            let statusPreparationReturn = { status: 'in_preparation' };
            this.pedidosService.trocarStatus(_id, statusPreparationReturn).subscribe(res => {
              this.filtrarPedidos();
            });
            break;
        }
      }
    });
  }

  cancelamentoPedido(pedido) {
    this.pedidoCancelamento = pedido._id;
    this.modalCancelamento = true;
  }

  pedidoCancelado() {
    this.fecharModalCancelamento();
    this.filtrarPedidos();
  }

  fecharModalCancelamento() {
    this.modalCancelamento = false;
  }
}
